import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { MainAPIClient } from '../../services/main-api-client';
import { LoginInput, SignUpInput } from '../../types';
import {
  unexpectedError,
  ValidationError,
  UnauthorizedError,
  generateErrorNotification,
  BusinessError,
} from '../../utils/errors';
import { LocalStorageKeys } from '../../utils/local_storage_keys';
import { initialState } from './state';

export const logIn = createAsyncThunk<void, { loginInput: LoginInput }, { extra: { mainApiClient: MainAPIClient } }>(
  'authentication/logIn',
  async ({ loginInput }, thunkAPI) => {
    const { mainApiClient } = thunkAPI.extra;
    thunkAPI.dispatch(authenticationSlice.actions.startLoading());

    try {
      const authData = await mainApiClient.logIn({ loginInput });
      localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, JSON.stringify(authData));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 401) {
          throw new UnauthorizedError('Make sure your credentials are correct.');
        } else if (e.response?.status === 422) {
          throw new ValidationError('The email you provided is not in a valid format.');
        }
      }
      throw e;
    }
    location.href = '/dashboard';
  },
);

export const signUp = createAsyncThunk<void, { signUpInput: SignUpInput }, { extra: { mainApiClient: MainAPIClient } }>(
  'authentication/signUp',
  async ({ signUpInput }, thunkAPI) => {
    const { mainApiClient } = thunkAPI.extra;
    thunkAPI.dispatch(authenticationSlice.actions.startLoading());

    try {
      const authData = await mainApiClient.signUp({ signUpInput });
      localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, JSON.stringify(authData));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 409) {
          throw new BusinessError(
            'The email you provided is already used. Please use it to log-in or provide another address.',
          );
        } else if (e.response?.status === 422) {
          throw new ValidationError('The email you provided is not in a valid format.');
        }
      }
      throw e;
    }
    location.href = '/dashboard';
  },
);

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    logOut: (state) => {
      localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
      state.isAuthenticated = false;
    },
    setIsAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logIn.fulfilled, (state) => {
      state.isAuthenticated = true;
      state.isLoading = false;
    });
    builder.addCase(logIn.rejected, (state, action) => {
      state.isLoading = false;
      if (action.error.name === 'UnauthorizedError' || action.error.name === 'ValidationError') {
        state.error = generateErrorNotification('Log in failed', action.error);
      } else {
        state.error = unexpectedError;
      }
    });
    builder.addCase(signUp.fulfilled, (state) => {
      state.isAuthenticated = true;
      state.isLoading = false;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoading = false;
      if (action.error.name === 'ValidationError' || action.error.name === 'BusinessError') {
        state.error = generateErrorNotification('Sign up failed', action.error);
      } else {
        state.error = unexpectedError;
      }
    });
  },
});

export const { clearError, logOut, setIsAuthenticated } = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
