import { myApplications } from './pages/my-applications';
import { settings } from './pages/settings';
import { errors } from './errors';
import { navigation } from './navigation';
import { dashboard } from './pages/dashboard';
import { logs } from './pages/logs';
import { documentation } from './pages/documentation';
import { feedback } from './pages/feedback';
import { modal } from './modal';

export const translations = {
  pages: {
    dashboard,
    myApplications,
    logs,
    documentation,
    settings,
    feedback,
  },
  errors,
  navigation,
  modal,
};
