import { SelectPicker } from 'rsuite';
import { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from '../theme-provider';

export interface SelectOption {
  label: string;
  value: any;
}

export interface SelectProps {
  data: SelectOption[];
  value: any;
  onChange: (value: any) => void;
  defaultValue?: any;
  disabled?: boolean;
}

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    '@global': {
      '.rs-picker-default .rs-btn, .rs-picker-input .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-picker-toggle':
        {
          backgroundColor: isDark ? `${palette.grey.dark.grey02} !important` : undefined,
          color: isDark ? `${palette.grey.dark.grey05} !important` : undefined,
        },
      '.rs-picker-select-menu rs-picker-menu': {
        backgroundColor: isDark ? `${palette.grey.dark.grey02} !important` : undefined,
        color: isDark ? `${palette.grey.dark.grey05} !important` : undefined,
      },
      '.rs-picker-toggle, .rs-btn, .rs-btn-default': {
        borderColor: isDark ? `${palette.grey.dark.grey03} !important` : undefined,
      },
      //Menu items
      '.rs-picker-select-menu ': {
        backgroundColor: isDark ? `${palette.grey.dark.grey02} !important` : undefined,
      },
      //Selected item background
      '.rs-picker-select-menu-item-active': {
        backgroundColor: isDark ? `${palette.primary.purple2} !important` : undefined,
      },
      //Item in the menu
      '.rs-picker-select-menu-item': {
        color: isDark ? `${palette.grey.dark.grey05} !important` : undefined,
        '&:hover': {
          backgroundColor: isDark ? `${palette.grey.dark.grey03} !important` : undefined,
        },
      },
      //Selected item
      '.rs-picker-toggle-value': {
        color: `${isDark ? palette.white : palette.black} !important`,
      },
      //Disabled style
      '.rs-picker-default.rs-picker-disabled .rs-picker-toggle, .rs-picker-input.rs-picker-disabled .rs-picker-toggle, .rs-picker-default.rs-picker-disabled .rs-btn, .rs-picker-input.rs-picker-disabled .rs-btn':
        {
          backgroundColor: `${isDark ? palette.grey.dark.grey03 : palette.grey.light.grey05} !important`,
        },
    },
  };
});

export const Select: FunctionComponent<SelectProps> = ({ data, value, onChange, defaultValue, disabled = false }) => {
  useStylesFromThemeFunction();

  return (
    <SelectPicker
      data={data}
      value={value}
      onChange={onChange}
      searchable={false}
      style={{ width: 224 }}
      cleanable={false}
      disabled={disabled}
      defaultValue={defaultValue}
    />
  );
};
