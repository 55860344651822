import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './state';

const appSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    finishLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { startLoading, finishLoading } = appSlice.actions;
export const appReducer = appSlice.reducer;
