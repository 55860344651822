import { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useToaster } from 'rsuite/toaster';
import { Button, Input, Logo, palette, Text } from '@my-logger/my-logger.ui';

import { Notification } from '../../components/notification';
import { clearError, signUp } from '../../redux/authentication/reducer';
import { AuthenticationState } from '../../redux/authentication/state';
import { RootState, useDispatch } from '../../redux/create-store';
import { ClientError, Pages } from '../../types';

import './style.less';

export const SignUp: FunctionComponent = () => {
  const reduxDispatch = useDispatch();
  const toaster = useToaster();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [cPassword, setCPassword] = useState<string>('');

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    let error: ClientError | null = null;

    event.preventDefault();
    if (password !== cPassword) {
      error = {
        title: 'Input error',
        message: 'Passwords do not match',
        type: 'error',
      };
    } else if (email === '' || password === '') {
      error = {
        title: 'Missing fields',
        message: 'Please provide both an email and a password.',
        type: 'error',
      };
    } else {
      reduxDispatch(signUp({ signUpInput: { email, password } }));
    }
    if (error) {
      toaster.push(Notification(error), { placement: 'topEnd' });
    }
  };

  const { isLoading, error } = useSelector<RootState, AuthenticationState>((state) => state.authentication);

  useEffect(() => {
    if (error) {
      toaster.push(Notification(error), { placement: 'topEnd' });
      reduxDispatch(clearError());
    }
  }, [error]);

  return (
    <div className="authentication-container">
      <div className="form-card">
        <div className="form-container">
          <div className="logo-container">
            <Logo tagText="alpha" color="#000000" />
          </div>
          <form onSubmit={onSubmit}>
            <Text variant="small" style={{ color: palette.black, marginBottom: 4 }}>
              Email
            </Text>
            <Input value={email} onChange={setEmail} style={{ border: '1px solid #e9e9e9' }} />
            <Text variant="small" style={{ color: palette.black, marginBottom: 4, marginTop: 8 }}>
              Password
            </Text>
            <Input value={password} onChange={setPassword} isPassword style={{ border: '1px solid #e9e9e9' }} />
            <Text variant="small" style={{ color: palette.black, marginBottom: 4, marginTop: 8 }}>
              Confirm password
            </Text>
            <Input value={cPassword} onChange={setCPassword} isPassword style={{ border: '1px solid #e9e9e9' }} />
            <Button className="login-button" loading={isLoading} type="submit" style={{ width: '100%' }}>
              Sign up
            </Button>
            <div className="signup-container">
              <Text variant="small" style={{ display: 'inline' }}>
                Already have an account?{' '}
              </Text>
              <Link to={Pages.Login}>
                <Text variant="small" style={{ display: 'inline' }} className="mlg-link">
                  Log In
                </Text>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
