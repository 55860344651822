export default {
  title: 'Options',
  subTitle: `Mettez vos préférences à jour et générez une nouvelle clé d'API`,
  darkModeLabel: 'Utiliser le mode sombre',
  languageLabel: 'Langue',
  highlightErrorsLabel: 'Surligner les erreurs',
  highlightWarningLabel: 'Surligner les warnings',
  apiKeyLabel: `Clé d'API`,
  apiKeyButtonText: `Générer une nouvelle clé d'API`,
  saveButtonText: 'Sauvegarder',
  apiKeyModals: {
    passwordModal: {
      title: 'Entrez votre mot de passe',
      text: `Nous avons besoin de votre mot de passe pour générer une nouvelle clé d'API`,
    },
    confirmationModal: {
      title: `Votre clé d'API`,
      text: `Assurez vous de garder votre clé d'API quelque part, car vous ne pourrez pas la revoir`,
    },
  },
};
