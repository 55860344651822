import { FunctionComponent } from 'react';
import { FlexboxGrid } from 'rsuite';
import MenuIcon from '@rsuite/icons/Menu';
import CloseIcon from '@rsuite/icons/Close';
import { Logo } from '../logo';
import { Theme } from '../theme-provider';
import { createUseStyles } from 'react-jss';

export interface MobileTopBarProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const useStylesFromThemeFunction = (open: boolean) =>
  createUseStyles((theme: Theme) => {
    const { isDark, palette } = theme;

    return {
      container: {
        '@global': {
          '.logo-text': {
            color: isDark ? `${palette.white} !important` : undefined,
          },
        },
        padding: 14,
        boxShadow: open ? undefined : '0px 0px 15px -3px rgba(0,0,0,0.1)',
        backgroundColor: isDark ? palette.black : undefined,
      },
    };
  });

export const MobileTopBar: FunctionComponent<MobileTopBarProps> = ({ open, setOpen }) => {
  const classes = useStylesFromThemeFunction(open)();

  return (
    <FlexboxGrid className={classes.container} justify="space-between" align="middle">
      <FlexboxGrid.Item colspan={4}>
        <Logo fontSize={22} />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2} onClick={() => setOpen(!open)} style={{ cursor: 'pointer', textAlign: 'right' }}>
        {open ? <CloseIcon style={{ fontSize: 16 }} /> : <MenuIcon style={{ fontSize: 16 }} />}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};
