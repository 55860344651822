export default {
  title: 'My applications',
  subtitle: 'Create and manage your applications.',
  advice: ['If you need help setting up MyLogger in your application, visit the ', 'documentation', ' page.'],
  seeLogs: 'See logs',
  deleteApplicationModal: {
    title: 'Delete application',
    warning: (name: string) =>
      `Are you sure you want to delete ${name}? This cannot be undone, and all logs attached to this application will be deleted.`,
  },
};
