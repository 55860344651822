import { CSSProperties, forwardRef, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Animation } from 'rsuite';
import { MobileTopBar, SideNavigation, Theme, createUseStyles, mobileBreakpoint } from '@my-logger/my-logger.ui';

import { ResponsiveAdapter } from '../utils/responsive-adapter';
import { getUserData } from '../../redux/user/reducer';
import { fetchUserSettings } from '../../redux/settings/reducer';
import { RootState, useDispatch } from '../../redux/create-store';
import { fetchUserApplications } from '../../redux/applications/reducer';
import { createMenuItems, MenuItem } from '../../utils/navigation';
import { finishLoading } from '../../redux/app/reducer';
import packageJson from '../../../package.json';

import './style.less';

const MobileNavigationContainer = forwardRef<
  HTMLDivElement,
  { style?: CSSProperties; activeKey: string; menuItems: MenuItem[] }
>((props, ref) => {
  const { menuItems, activeKey, ...rest } = props;
  return (
    <div {...rest} ref={ref}>
      <SideNavigation
        {...props}
        activeKey={activeKey}
        menuItems={menuItems}
        hideLogo
        style={{ position: 'absolute', top: 0, ...(props.style || {}) }}
        expandable={false}
        logoProps={{ tagText: 'alpha' }}
        version={`v${packageJson.version}`}
      />
    </div>
  );
});
MobileNavigationContainer.displayName = 'MobileNavigationContainer';

interface Props {
  children?: React.ReactNode;
}

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    '@global': {
      'html, body': {
        backgroundColor: isDark ? palette.grey.dark.grey01 : palette.grey.light.grey06,
      },
    },
  };
});

export const AppContainer: FunctionComponent<Props> = ({ children }) => {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { userId } = useSelector<RootState, { userId: number | null }>((state) => state.user);
  const fetchError = useSelector<RootState, boolean>(
    (state) => !!state.applications.error || !!state.settings.fetchingSettingsError,
  );
  const isLoading = useSelector<RootState, boolean>((state) => state.app.isLoading);
  const [openMobileNav, setOpenMobileNav] = useState<boolean>(false);

  const menuItems = createMenuItems(navigate, () => setOpenMobileNav(false));
  const activeKey = useMemo(() => pathname.slice(1), [pathname]);

  useStylesFromThemeFunction();

  const fetchData = async () => {
    if (userId !== null) {
      await reduxDispatch(fetchUserSettings({ userId }));
      await reduxDispatch(fetchUserApplications({ userId }));
      await reduxDispatch(finishLoading());
    }
  };

  //First, we fetch the id of the current logged in user
  useEffect(() => {
    reduxDispatch(getUserData());
  }, []);

  //Then, we fetch everything else
  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  useEffect(() => {
    document.body.style.overflowY = openMobileNav ? 'hidden' : 'unset';
  }, [openMobileNav]);

  if (!isLoading && fetchError) {
    //TODO: Add actual error page
    return (
      <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <p>Error while fetching data</p>
      </div>
    );
  } else {
    return (
      <div className="my-logger-root-container">
        <div className="navigation-container" style={{ height: 58 }}>
          <ResponsiveAdapter
            mobile={
              <>
                <MobileTopBar open={openMobileNav} setOpen={setOpenMobileNav} />
                <Animation.Slide in={openMobileNav} placement="right">
                  {(props, ref) => (
                    <MobileNavigationContainer
                      {...props}
                      menuItems={menuItems}
                      activeKey={activeKey}
                      style={{
                        height: `calc(100vh - 58px)`,
                      }}
                      ref={ref}
                    />
                  )}
                </Animation.Slide>
              </>
            }
            desktop={
              <SideNavigation
                menuItems={menuItems}
                activeKey={activeKey}
                version={`v${packageJson.version}`}
                logoProps={{ tagText: 'alpha' }}
                isLoading={isLoading}
              />
            }
            breakpoint={mobileBreakpoint}
          />
        </div>
        <div style={{ width: '100%' }}>{children}</div>
      </div>
    );
  }
};
