export default {
  title: 'Logs',
  subtitle: 'Browse and search through your application logs.',
  table: {
    date: 'Date',
    application: 'Application',
    level: 'Level',
    message: 'Message',
    emptyText: `We didn't find any log.`,
    errorText: `We're having issues fetching your logs. Don't worry, they are in our system. We are looking into it.`,
  },
  filters: {
    searchPlaceholder: 'Type something to search through your logs...',
    liveLogs: 'Live logs',
    datePickerPlaceholder: 'Date and time range',
    loadingMore: 'Loading more...',
    all: {
      masculine: 'All',
      feminine: 'All',
    },
    lastNthMins: (nb: number) => (nb === 1 ? 'Last minute' : `${nb} last mins`),
    lastNthDays: (nb: number) => (nb === 1 ? 'Last day' : `${nb} last days`),
    lastNthHours: (nb: number) => (nb === 1 ? 'Last hour' : `${nb} last hours`),
  },
  modal: {
    title: 'Log details',
    at: 'at',
  },
};
