import axios from 'axios';
import { AuthData } from '@my-logger/my-logger.types';

import { LocalStorageKeys } from '../../utils/local_storage_keys';
import { generateApiKeyFactory } from './api-key/generate-api-key';
import { logInFactory } from './authentication/log-in';
import { refreshTokenFactory } from './authentication/refresh-token';
import { signUpFactory } from './authentication/sign-up';
import { getUserDataFactory } from './user/get-user-data';
import { getUserSettingsFactory } from './user/get-user-settings';
import { updateUserSettingsFactory } from './user/update-user-settings';
import { getUserApplicationsFactory } from './applications/get-user-applications';
import { createUserApplicationsFactory } from './applications/create-application';
import { editUserApplicationsFactory } from './applications/edit-application';
import { deleteUserApplicationFactory } from './applications/delete-application';
import { getLogsFactory } from './logs/get-logs';

const endpointsToIgnoreRefresh = ['/auth/log-in', '/auth/sign-up'];

export const createApiClient = ({ mainApiUrl }: { mainApiUrl: string }) => {
  const mainApiClient = axios.create({ baseURL: mainApiUrl });

  const refreshAuthAPICall = refreshTokenFactory({ mainApiClient });

  const refreshAuthLogic = async () => {
    const { refreshToken } = getTokenFromLocalStorage();
    if (refreshToken) {
      const newAuthData = await refreshAuthAPICall({ refreshToken });
      localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, JSON.stringify(newAuthData));
      return newAuthData;
    }
    return null;
  };

  const getTokenFromLocalStorage = (): Partial<AuthData> => {
    const authData = localStorage.getItem(LocalStorageKeys.AUTH_TOKEN);
    if (authData) {
      return JSON.parse(authData) as AuthData;
    }
    return {};
  };

  mainApiClient.interceptors.request.use((config) => {
    const { token } = getTokenFromLocalStorage();

    if (config.headers && token) {
      config.headers['Authorization'] = token ? `Bearer ${token}` : '';
    }
    return config;
  });

  mainApiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const config = error?.config;

      if (config.url === '/auth/refresh') {
        location.href = '/log-in';
      } else if (error?.response?.status === 401 && !config?.sent && !endpointsToIgnoreRefresh.includes(config.url)) {
        config.sent = true;

        const result = await refreshAuthLogic();

        if (result) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${result.token}`,
          };
        }

        return axios(config);
      }
      return Promise.reject(error);
    },
  );

  return {
    logIn: logInFactory({ mainApiClient }),
    signUp: signUpFactory({ mainApiClient }),
    generateAPIKey: generateApiKeyFactory({ mainApiClient }),
    getUserData: getUserDataFactory({ mainApiClient }),
    getUserApplications: getUserApplicationsFactory({ mainApiClient }),
    createUserApplication: createUserApplicationsFactory({ mainApiClient }),
    editUserApplication: editUserApplicationsFactory({ mainApiClient }),
    deleteUserApplication: deleteUserApplicationFactory({ mainApiClient }),
    getUserSettings: getUserSettingsFactory({ mainApiClient }),
    updateUserSettings: updateUserSettingsFactory({ mainApiClient }),
    getLogs: getLogsFactory({ mainApiClient }),
  };
};

export type MainAPIClient = ReturnType<typeof createApiClient>;
