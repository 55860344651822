export default {
  title: 'Logs',
  subtitle: `Parcourez les logs de vos applications.`,
  table: {
    date: 'Date',
    application: 'Application',
    level: 'Niveau',
    message: 'Message',
    emptyText: `Nous n'avons pas trouvé de log.`,
    errorText:
      'Il semble que nous ne parvenons pas à récupérer vos logs. Ne vous inquiétez pas, ils sont dans toujours dans notre système. Nous travaillons pour régler le problème.',
  },
  filters: {
    searchPlaceholder: 'Ecrivrez quelque chose pour effectuer une recherche dans vos logs...',
    liveLogs: 'Logs en direct',
    datePickerPlaceholder: 'Dates personalisées',
    loadingMore: 'Chargement...',
    all: {
      masculine: 'Tous',
      feminine: 'Toutes',
    },
    lastNthMins: (nb: number) => (nb === 1 ? 'Dernière minute' : `${nb} dernières minutes`),
    lastNthDays: (nb: number) => (nb === 1 ? 'Dernier jour' : `${nb} derniers jours`),
    lastNthHours: (nb: number) => (nb === 1 ? 'Dernière heure' : `${nb} dernières heures`),
  },
  modal: {
    title: 'Détails',
    at: 'à',
  },
};
