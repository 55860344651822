import { MessageType } from 'rsuite/esm/Notification/Notification';

export interface LoginInput {
  email: string;
  password: string;
}

export type SignUpInput = LoginInput;

export interface ClientError {
  type: MessageType;
  message: string;
  title: string;
}

export enum Pages {
  SignUp = '/sign-up',
  Login = '/log-in',
  Dashboard = '/dashboard',
  MyApplications = '/my-applications',
  Logs = '/logs',
  Documentation = '/documentation',
  Settings = '/settings',
  Feedback = '/feedback',
}

export type SaveButtonStatus = 'success' | 'error';
