import SettingIcon from '@rsuite/icons/Setting';
import DashboardIcon from '@rsuite/icons/Dashboard';
import SiteIcon from '@rsuite/icons/Site';
import GridIcon from '@rsuite/icons/Grid';
//import WechatOutlineIcon from '@rsuite/icons/WechatOutline';
import PageIcon from '@rsuite/icons/Page';
import { NavigateFunction } from 'react-router-dom';

export interface MenuItem {
  label: string;
  icon: JSX.Element;
  eventKey: string;
  onClick: () => void;
}

export const createMenuItems = (navigate: NavigateFunction, cb: () => void): MenuItem[] => [
  {
    label: 'Dashboard',
    icon: <DashboardIcon />,
    eventKey: 'dashboard',
    onClick: () => {
      navigate('/dashboard');
      cb();
    },
  },
  {
    label: 'My applications',
    icon: <GridIcon />,
    eventKey: 'my-applications',
    onClick: () => {
      navigate('/my-applications');
      cb();
    },
  },
  {
    label: 'Logs',
    icon: <SiteIcon />,
    eventKey: 'logs',
    onClick: () => {
      navigate('/logs');
      cb();
    },
  },
  {
    label: 'Documentation',
    icon: <PageIcon />,
    eventKey: 'documentation',
    onClick: () => {
      navigate('/documentation');
      cb();
    },
  },
  {
    label: 'Settings',
    icon: <SettingIcon />,
    eventKey: 'settings',
    onClick: () => {
      navigate('/settings');
      cb();
    },
  },
  /* Removed until this page is developed {
    label: 'Feedback',
    icon: <WechatOutlineIcon />,
    eventKey: 'feedback',
    onClick: () => {
      navigate('/feedback');
      cb();
    },
  },*/
];
