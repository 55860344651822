import { Language } from '@my-logger/my-logger.types';
import en from './en';
import fr from './fr';

type Definition = {
  dashboard: string;
  myApps: string;
  logs: string;
  documentation: string;
  settings: string;
  feedback: string;
  logOut: string;
};

export const navigation: { [key: string]: Definition } = {
  [Language.English]: en,
  [Language.French]: fr,
};
