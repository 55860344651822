import { FunctionComponent, useEffect, useState } from 'react';
import { CircleLoader } from '@my-logger/my-logger.ui';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { setIsAuthenticated } from '../../redux/authentication/reducer';
import { RootState, useDispatch } from '../../redux/create-store';
import { Pages } from '../../types';
import { LocalStorageKeys } from '../../utils/local_storage_keys';

type Props = {
  children?: React.ReactNode;
};

export const PrivateWrapper: FunctionComponent<Props> = ({ children }) => {
  const [checkedLocalStorage, setCheckedLocalStorage] = useState<boolean>(false);

  const isAuthenticated = useSelector<RootState, boolean>((state) => state.authentication.isAuthenticated);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    const serializedObject = localStorage.getItem(LocalStorageKeys.AUTH_TOKEN);
    if (serializedObject && JSON.parse(serializedObject)) {
      reduxDispatch(setIsAuthenticated());
      setCheckedLocalStorage(true);
    } else {
      location.href = '/log-in';
    }
  }, []);

  if (isAuthenticated) {
    return <>{children}</>;
  } else if (!checkedLocalStorage) {
    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircleLoader />
      </div>
    );
  }
  return <Navigate to={Pages.Login} />;
};
