import { ClientError, SaveButtonStatus } from '../../types';
import { Language, UserSettings } from '@my-logger/my-logger.types';

export type SettingsState = {
  status?: SaveButtonStatus;
  isLoading: boolean;
  apiKey: string | null;
  fetchingSettingsError?: ClientError;
  error?: ClientError;
  settings: UserSettings;
};

export const initialState: SettingsState = {
  isLoading: false,
  apiKey: null,
  settings: {
    language: Language.English,
  } as unknown as UserSettings,
};
