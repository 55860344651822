export interface User {
  id: number;
  email: string;
  createdAt: Date;
  hash: string;
}

export type UserWithoutHash = Omit<User, 'hash'>;

export interface AuthData {
  token: string;
  refreshToken: string;
}

export enum Language {
  English = 'en',
  French = 'fr',
}

export interface UserSettings {
  language: Language;
  useDarkMode: boolean;
  areErrorsHighlighted: boolean;
  areWarningsHighlighted: boolean;
}

export interface Application {
  id: number;
  user: number;
  name: string;
  description?: string;
  logsCount: number;
  color: string;
}

export enum LogLevel {
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
  Debug = 'debug',
}

export interface JoiErrorMessage {
  key: string;
  error: string;
}

export interface ValidationErrorDetails {
  body?: JoiErrorMessage[];
  query?: JoiErrorMessage[];
  params?: JoiErrorMessage[];
}

export interface Log {
  application: string;
  message: string;
  creationDate: Date;
  level: LogLevel;
}

export interface PaginationFilters {
  pageSize: number;
  page: number;
}

export interface SearchFilters {
  search?: string;
  dates?: [Date | null, Date | null];
  applications: string[];
  levels: LogLevel[];
}

export type PaginatedResults<T> = {
  results: {
    data: T[];
    totalResults: number;
  };
  pagination: {
    currentPage: string;
    nextPage: string;
  };
};
