import { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Application, UserSettings } from '@my-logger/my-logger.types';
import { List, palette, Placeholder, Text } from '@my-logger/my-logger.ui';

import { PageHeader } from '../../components/page-header';
import { translations } from '../../lang';
import { RootState } from '../../redux/create-store';
import { Pages } from '../../types';
import { DeleteApplicationModal } from './modal/delete-application';

import './style.less';

export const MyApplications: FunctionComponent = () => {
  const [appIdToDelete, setAppIdToDelete] = useState<number | null>(null);
  const { language } = useSelector<RootState, UserSettings>((state) => state.settings.settings);
  const applications = useSelector<RootState, Application[]>((state) => state.applications.applications);
  const isLoading = useSelector<RootState, boolean>((state) => state.app.isLoading);

  const T = translations.pages.myApplications[language];

  return (
    <div className="page-container">
      <div className="my-applications-page">
        {!isLoading && (
          <DeleteApplicationModal
            setOpen={() => setAppIdToDelete(null)}
            application={applications.find((app) => app.id === appIdToDelete)}
          />
        )}
        <PageHeader isLoading={isLoading} title={T.title} subtitle={T.subtitle} />

        <div className="applications-container">
          {isLoading ? (
            <Placeholder width={300} style={{ marginBottom: 20 }} height={16} active />
          ) : (
            <div style={{ display: 'inline', marginBottom: 20 }}>
              <Text variant="small" style={{ display: 'inline' }}>
                {T.advice[0]}
              </Text>
              <Link to={Pages.Documentation} style={{ display: 'inline' }}>
                <a className="advice-text-highlight">
                  <Text variant="small" style={{ display: 'inline', color: palette.primary.purple1 }}>
                    {T.advice[1]}
                  </Text>
                </a>
              </Link>
              <Text variant="small" style={{ display: 'inline' }}>
                {T.advice[2]}
              </Text>
            </div>
          )}
          <div style={{ width: '100%' }}>
            <List
              isLoading={isLoading}
              listItems={[...applications]
                .sort((a, b) => (a.logsCount < b.logsCount ? 1 : -1))
                .map((app) => ({
                  ...app,
                  previewNumber: app.logsCount,
                  iconColor: app.color,
                  handleDelete: () => setAppIdToDelete(app.id),
                }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
