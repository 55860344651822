import axios from 'axios';
import { UserSettings } from '@my-logger/my-logger.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { MainAPIClient } from '../../services/main-api-client';
import { unexpectedError, generateErrorNotification, UnauthorizedError } from '../../utils/errors';
import { RootState } from '../create-store';
import { initialState } from './state';

export const generateApikey = createAsyncThunk<
  { apiKey: string },
  { password: string },
  { extra: { mainApiClient: MainAPIClient }; state: RootState }
>('settings/api-key.generate', async ({ password }, thunkAPI) => {
  let apiKey = null;

  const { mainApiClient } = thunkAPI.extra;

  thunkAPI.dispatch(settingsSlice.actions.startLoading());

  const userId = thunkAPI.getState().user.userId;

  if (!userId) {
    throw new UnauthorizedError();
  }

  try {
    apiKey = await mainApiClient.generateAPIKey({ userId, password });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.status === 401) {
        throw new UnauthorizedError('The password you provided is incorrect');
      }
    }
    throw e;
  }
  return apiKey;
});

export const fetchUserSettings = createAsyncThunk<
  UserSettings,
  { userId: number },
  { extra: { mainApiClient: MainAPIClient } }
>('settings/get', async ({ userId }, thunkAPI) => {
  let settings = null;

  const { mainApiClient } = thunkAPI.extra;

  thunkAPI.dispatch(settingsSlice.actions.startLoading());

  settings = await mainApiClient.getUserSettings({ userId });
  thunkAPI.dispatch(updateTheme(settings.useDarkMode));

  return settings;
});

export const updateUserSettings = createAsyncThunk<
  UserSettings,
  { settings: UserSettings },
  { extra: { mainApiClient: MainAPIClient }; state: RootState }
>('settings/update', async ({ settings }, thunkAPI) => {
  const { mainApiClient } = thunkAPI.extra;

  const userId = thunkAPI.getState().user.userId;

  if (!userId) {
    throw new UnauthorizedError();
  }

  thunkAPI.dispatch(settingsSlice.actions.startLoading());
  await mainApiClient.updateUserSettings({ settings, userId });

  thunkAPI.dispatch(updateTheme(settings.useDarkMode));

  return settings;
});

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    clearApiKey: (state) => {
      state.apiKey = null;
    },
    updateTheme: (_, action) => {
      const theme = action.payload ? 'dark' : 'light';
      document.documentElement.className = `${theme}-theme`;
    },
    clearStatus: (state) => {
      state.status = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateApikey.fulfilled, (state, action) => {
      state.isLoading = false;
      state.apiKey = action.payload.apiKey;
    });
    builder.addCase(generateApikey.rejected, (state, action) => {
      state.isLoading = false;
      if (action.error.name === 'UnauthorizedError') {
        state.error = generateErrorNotification('Password validation failed', action.error);
      } else {
        state.error = unexpectedError;
      }
    });
    builder.addCase(fetchUserSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.settings = action.payload;
    });
    builder.addCase(fetchUserSettings.rejected, (state) => {
      state.isLoading = false;
      state.fetchingSettingsError = unexpectedError;
    });
    builder.addCase(updateUserSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = 'success';
      state.settings = action.payload;
    });
    builder.addCase(updateUserSettings.rejected, (state) => {
      state.isLoading = false;
      state.status = 'error';
      state.error = unexpectedError;
    });
  },
});

export const settingsReducer = settingsSlice.reducer;
export const { clearError, clearApiKey, updateTheme, clearStatus } = settingsSlice.actions;
