import { CSSProperties, FunctionComponent } from 'react';
import { Table as RTable, TableProps as RTableProps } from 'rsuite';
import { createUseStyles } from 'react-jss';

import { Theme } from '../theme-provider';
import { palette } from '../../theme/palettes';
import { CircleLoader } from '../circle-loader';

const { Column, HeaderCell, Cell } = RTable;

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    '@global': {
      '.rs-table-row': {
        '&:hover .rs-table-cell-content': {
          cursor: 'pointer',
          backgroundColor: isDark ? palette.grey.dark.grey02 : undefined,
        },
      },
      '.rs-table-row-header': {
        backgroundColor: 'inherit',
        color: isDark ? palette.grey.dark.grey06 : palette.black,
      },
      '.rs-table-cell': {
        backgroundColor: 'inherit',
        color: isDark ? palette.grey.dark.grey05 : palette.grey.light.grey02,
        borderBottom: `1px solid ${isDark ? palette.grey.dark.grey03 : palette.grey.light.grey04}`,
      },
      '.rs-table-row-header, .rs-table-cell-content': {
        backgroundColor: 'inherit',
      },
      '.rs-table-row-header .rs-table-cell-content': {
        backgroundColor: isDark ? palette.grey.dark.grey02 : palette.grey.light.grey02,
        color: isDark ? undefined : palette.grey.light.grey06,
        '&:hover .rs-table-cell-content': {
          backgroundColor: isDark ? palette.grey.dark.grey01 : undefined,
        },
      },
    },
    tableContainer: {
      position: 'relative',
      borderRadius: 8,
      boxShadow: isDark ? '2px 2px 10px rgba(10, 10, 10, 0.25)' : '0px 2px 4px rgba(73, 80, 87, 0.25)',
    },
    loaderContainer: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: isDark ? palette.black : palette.grey.light.grey05,
      color: isDark ? palette.grey.dark.grey05 : palette.grey.light.grey02,
      padding: '10px 20px',
      bottom: 0,
      right: '50%',
      transform: 'translate(50%,-50%)',
      borderRadius: 8,
      boxShadow: isDark ? '2px 2px 10px rgba(10, 10, 10, 0.25)' : '0px 2px 4px rgba(73, 80, 87, 0.25)',
    },
  };
});

export interface TableColumn {
  columnName: string;
  width: number;
  dataKey: string;
  flexGrow?: boolean;
  color?: string;
}

export interface TableProps extends RTableProps<any, any> {
  loading: boolean;
  data: any[];
  onItemClick?: (item: any) => void;
  rowClassName?: ((rowData: any, rowIndex: number) => string) | undefined;
  columns: TableColumn[];
  loadingMoreText: string;
  containerStyle?: CSSProperties;
}

const MoreDataLoading: FunctionComponent<Pick<TableProps, 'loadingMoreText'>> = ({ loadingMoreText }) => {
  const classes = useStylesFromThemeFunction();

  return (
    <div className={classes.loaderContainer}>
      <CircleLoader style={{ marginRight: 16, color: palette.primary.purple1 }} size="xs" />
      {loadingMoreText}
    </div>
  );
};

export const Table: FunctionComponent<TableProps> = ({
  data,
  loading,
  rowClassName,
  onItemClick,
  columns,
  loadingMoreText,
  containerStyle,
  ...rest
}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <div className={classes.tableContainer} style={containerStyle}>
      <RTable
        autoHeight
        data={data}
        rowClassName={rowClassName}
        style={{ borderRadius: 'inherit' }}
        onRowClick={onItemClick ? (row) => onItemClick(row) : undefined}
        {...rest}
      >
        {columns.map((col, index) => (
          <Column width={col.width} flexGrow={col.flexGrow ? 1 : undefined} key={index}>
            <HeaderCell>{col.columnName}</HeaderCell>
            <Cell dataKey={col.dataKey} style={{ color: col.color }} />
          </Column>
        ))}
      </RTable>
      {loading && <MoreDataLoading loadingMoreText={loadingMoreText} />}
    </div>
  );
};
