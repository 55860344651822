import { AuthData } from '@my-logger/my-logger.types';
import { ClientError } from '../../types';

export type AuthenticationState = {
  isAuthenticated: boolean;
  authData: AuthData | null;
  isLoading: boolean;
  error?: ClientError;
};

export const initialState: AuthenticationState = {
  isAuthenticated: false,
  isLoading: false,
  authData: null,
};
