import { Log, PaginatedResults, PaginationFilters, SearchFilters } from '@my-logger/my-logger.types';
import { AxiosInstance } from 'axios';

export const getLogsFactory =
  ({ mainApiClient }: { mainApiClient: AxiosInstance }) =>
  async ({
    userId,
    pagination,
    filters,
  }: {
    userId: number;
    pagination: PaginationFilters;
    filters: SearchFilters;
  }): Promise<PaginatedResults<Log>> => {
    const { page, pageSize } = pagination;

    return (
      await mainApiClient.get<PaginatedResults<Log>>(`/api/user/${userId}/logs?page=${page}&pageSize=${pageSize}`, {
        params: filters,
      })
    ).data;
  };
