import { Text } from '@my-logger/my-logger.ui';
import { FunctionComponent } from 'react';

interface Props {
  isLoading: boolean;
  title?: string;
  subtitle?: string;
}

export const PageHeader: FunctionComponent<Props> = ({ isLoading, title, subtitle }) => {
  return (
    <>
      <Text isLoading={isLoading} variant="h1" style={{ display: 'block', marginTop: isLoading ? 16 : undefined }}>
        {title}
      </Text>
      <Text isLoading={isLoading} variant="h2" style={{ display: 'block', marginTop: isLoading ? 24 : undefined }}>
        {subtitle}
      </Text>
    </>
  );
};
