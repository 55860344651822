import { forwardRef, FunctionComponent, Ref, useState } from 'react';
import { Input as RInput, InputGroup } from 'rsuite';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import CopyIcon from '@rsuite/icons/Copy';
import { createUseStyles } from 'react-jss';
import { Theme } from '../theme-provider';

export interface InputProps {
  /** Override styles */
  style?: React.CSSProperties;
  /** ClassName */
  className?: string;
  /** Input should have type password */
  isPassword?: boolean;
  /** Input value */
  value: string;
  /** Method triggered on change */
  onChange?: (value: string) => void;
  /** Make the input read only */
  readOnly?: boolean;
  /** Applies maximum number of characters and show character count */
  characterCount?: number;
  /** Input placeholder */
  placeholder?: string;
  /** Ref object to attach to the input group */
  ref?: Ref<HTMLDivElement | null>;
}

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    group: {
      color: isDark ? palette.grey.dark.grey05 : undefined,
      borderColor: isDark ? palette.grey.dark.grey03 : undefined,
    },
    input: {
      backgroundColor: isDark ? `${palette.grey.dark.grey02} !important` : undefined,
      color: isDark ? `${palette.grey.dark.grey05} !important` : undefined,
    },
  };
});

export const Input: FunctionComponent<InputProps> = forwardRef<HTMLDivElement | null, InputProps>(
  ({ style, isPassword, value, onChange, readOnly, className, placeholder, characterCount }, ref) => {
    const [isShowingPassword, setIsShowingPassword] = useState<boolean>(false);

    if (!readOnly && !onChange) {
      throw new Error('Either readOnly or onChange must be provided');
    }
    if (isPassword) {
      readOnly = false;
      characterCount = undefined;
    }
    if (characterCount === 0) characterCount = undefined;

    const classes = useStylesFromThemeFunction();

    return (
      <InputGroup className={`${classes.group} ${className}`} style={style} inside ref={ref}>
        <RInput
          value={value}
          onChange={onChange}
          type={isPassword && !isShowingPassword ? 'password' : 'text'}
          readOnly={readOnly}
          maxLength={characterCount}
          className={classes.input}
          placeholder={placeholder}
        />
        {characterCount ? (
          <InputGroup.Button>
            {value.length}/{characterCount}
          </InputGroup.Button>
        ) : isPassword ? (
          <InputGroup.Button onClick={() => setIsShowingPassword(!isShowingPassword)}>
            {isShowingPassword ? <EyeIcon /> : <EyeSlashIcon />}
          </InputGroup.Button>
        ) : readOnly ? (
          <InputGroup.Button onClick={() => navigator.clipboard.writeText(value)}>
            <CopyIcon />
          </InputGroup.Button>
        ) : (
          <></>
        )}
      </InputGroup>
    );
  },
);

Input.displayName = 'Input';
