import { Application, UserSettings } from '@my-logger/my-logger.types';
import { Button, Card, CircleLoader, List, palette, Placeholder, Text } from '@my-logger/my-logger.ui';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { translations } from '../../lang';
import { RootState } from '../../redux/create-store';
import { Pages } from '../../types';
import { PageHeader } from '../../components/page-header';

import './style.less';

ChartJS.register(...registerables);

const generateRandomValues = ({ range, length }: { range: number; length: number }) =>
  Array.from({ length }, () => Math.floor(Math.random() * range));

export const Dashboard: FunctionComponent = () => {
  const navigate = useNavigate();

  const { language } = useSelector<RootState, UserSettings>((state) => state.settings.settings);
  const applications = useSelector<RootState, Application[]>((state) => state.applications.applications);
  const [graphWidth, setGraphWidth] = useState<number | null>(null);
  const graphRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setGraphWidth(graphRef?.current?.getBoundingClientRect().width || null);
  }, [graphRef.current]);

  const isLoading = useSelector<RootState, boolean>((state) => state.app.isLoading);

  const T = translations.pages.dashboard[language];

  return (
    <div className="page-container">
      <div className="dashboard-page">
        <PageHeader isLoading={isLoading} title={T.title} subtitle={T.subtitle} />
        <div className="dashboard-container">
          <div className="left-container" ref={graphRef}>
            <Card className="graph-container" style={{ height: 270 }}>
              {isLoading || !graphWidth ? (
                <CircleLoader />
              ) : (
                <Line
                  width={graphWidth}
                  height={250}
                  data={{
                    labels: ['08:00', '08:10', '08:20', '08:30', '08:40', '08:50', '09:00'],
                    datasets: [
                      {
                        label: 'Logs per minute',
                        fill: 'start',
                        backgroundColor: palette.success.success02,
                        borderColor: palette.success.success01,
                        borderWidth: 1,
                        tension: 0.4,
                        data: generateRandomValues({ length: 7, range: 100 }),
                      },
                    ],
                  }}
                />
              )}
            </Card>
            <Card className="graph-container" style={{ height: 270 }}>
              {isLoading || !graphWidth ? (
                <CircleLoader />
              ) : (
                <Line
                  width={graphWidth}
                  height={250}
                  data={{
                    labels: ['0.1%', '0.2%', '0.3%', '0.4%', '0.5%'],
                    datasets: [
                      {
                        label: 'Errors per minute',
                        fill: 'start',
                        backgroundColor: palette.error.error02,
                        borderColor: palette.error.error01,
                        borderWidth: 1,
                        tension: 0.4,
                        data: generateRandomValues({ length: 18, range: 4 }),
                      },
                    ],
                  }}
                />
              )}
            </Card>
          </div>
          <div className="right-container">
            <Card className="message-container" style={{ width: '100%' }}>
              <Text isLoading={isLoading} variant="h3" style={{ marginBottom: 16 }}>
                Welcome to our beta
              </Text>
              {isLoading ? (
                <>
                  <Placeholder style={{ width: '100%', marginBottom: 7 }} height={14} active />
                  <Placeholder style={{ width: '100%', marginBottom: 7 }} height={14} active />
                  <Placeholder style={{ width: '100%', marginBottom: 7 }} height={14} active />
                  <Placeholder style={{ width: '100%', marginBottom: 7 }} height={14} active />
                </>
              ) : (
                <Text>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventor veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo.
                </Text>
              )}
              <div className="message-button-container">
                <Button loading={isLoading} disabled={isLoading}>
                  Contact us
                </Button>
              </div>
            </Card>
            <div className="applications-container">
              <Text isLoading={isLoading} variant="h3" style={{ marginBottom: 16 }}>
                Applications
              </Text>
              <List
                isLoading={isLoading}
                listItems={[...applications]
                  .sort((a, b) => (a.logsCount < b.logsCount ? 1 : -1))
                  .slice(0, 3)
                  .map((app) => ({
                    ...app,
                    iconColor: app.color,
                    previewNumber: app.logsCount,
                  }))}
              />
              <div className="applications-button-container">
                <Button loading={isLoading} disabled={isLoading} onClick={() => navigate(Pages.MyApplications)}>
                  See all apps
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
