export default {
  title: 'Mes applications',
  subtitle: `Crééez et gérez vos applications.`,
  advice: [
    `Besoin d'aide pour mettre en place MyLogger dans vos applications? N'hésitez pas à visiter notre page de `,
    'documentation',
    '.',
  ],
  seeLogs: 'Voir logs',
  deleteApplicationModal: {
    title: 'Delete application',
    warning: (name: string) =>
      `Êtes vous certain de vouloir supprimer ${name}? Cette action ne peut pas être annulée, et tous logs liés à cette application seront définitivement supprimés.`,
  },
};
