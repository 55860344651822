import { AnyAction, combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch as useReduxDispatch } from 'react-redux';

import { createApiClient, MainAPIClient } from '../services/main-api-client';
import { settingsReducer } from './settings/reducer';
import { SettingsState } from './settings/state';
import { authenticationReducer } from './authentication/reducer';
import { AuthenticationState } from './authentication/state';
import { userReducer } from './user/reducer';
import { UserState } from './user/state';
import { applicationsReducer } from './applications/reducer';
import { UserApplicationState } from './applications/state';
import { appReducer } from './app/reducer';
import { AppState } from './app/state';
import { logsReducer } from './logs/reducer';
import { LogsState } from './logs/state';

const rootReducer = combineReducers({
  app: appReducer,
  authentication: authenticationReducer,
  settings: settingsReducer,
  user: userReducer,
  logs: logsReducer,
  applications: applicationsReducer,
});

export const createStore = ({ mainApiUrl }: { mainApiUrl: string }) => {
  const mainApiClient = createApiClient({ mainApiUrl });
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { mainApiClient },
        },
      }),
  });
  return store;
};

export type RootState = {
  app: AppState;
  authentication: AuthenticationState;
  settings: SettingsState;
  user: UserState;
  logs: LogsState;
  applications: UserApplicationState;
};

export type ReduxState = ReturnType<typeof rootReducer>;
export const useDispatch = () =>
  useReduxDispatch<ThunkDispatch<ReduxState, { mainApiClient: MainAPIClient }, AnyAction>>();
