import { FunctionComponent } from 'react';
import { useWindowDimensions } from '../../../custom-hooks/use-window-dimensions';

export interface Props {
  mobile: React.ReactNode;
  desktop: React.ReactNode;
  breakpoint: number;
}

export const ResponsiveAdapter: FunctionComponent<Props> = ({ breakpoint, mobile, desktop }) => {
  const { width } = useWindowDimensions();
  const isMobile = width <= breakpoint;

  return <>{isMobile ? mobile : desktop}</>;
};
