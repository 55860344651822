import { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { Toggle as RToggle } from 'rsuite';
import { Theme } from '../theme-provider';
import './index.less';

export interface ToggleProps {
  value: boolean;
  setValue: (value: boolean) => void;
  disabled?: boolean;
}

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    '@global': {
      '.rs-toggle-presentation': {
        backgroundColor: isDark ? palette.grey.dark.grey03 : undefined,
        '&:hover': {
          backgroundColor: isDark ? palette.grey.dark.grey04 : undefined,
        },
      },
      '.rs-toggle-checked .rs-toggle-presentation': {
        backgroundColor: palette.primary.purple1,
        '&:hover': {
          backgroundColor: palette.primary.purple2,
        },
      },
      '.rs-toggle-disabled .rs-toggle-presentation': {
        backgroundColor: isDark ? palette.grey.dark.grey03 : palette.grey.light.grey04,
        color: isDark ? palette.grey.dark.grey02 : 'blue',
        boxShadow: 'unset',
      },
    },
  };
});

export const Toggle: FunctionComponent<ToggleProps> = ({ value, setValue, disabled = false }) => {
  useStylesFromThemeFunction();

  return <RToggle onChange={setValue} checked={value} disabled={disabled} />;
};
