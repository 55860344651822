import { CSSProperties, FunctionComponent } from 'react';
import { Loader } from 'rsuite';

export interface CircleLoaderProps {
  style?: CSSProperties;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  className?: string;
  color?: string;
}

export const CircleLoader: FunctionComponent<CircleLoaderProps> = ({ style, size = 'md', className, color }) => {
  return <Loader size={size} style={style} className={className} color={color} />;
};
