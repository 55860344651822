import { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from '../theme-provider';

export interface LogoProps {
  /** Tag text to be displayed on the right */
  tagText?: string;
  /** Override text color */
  color?: string;
  /** Text font size */
  fontSize?: number;
}

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    logoText: {
      position: 'relative',
      color: isDark ? palette.white : palette.black,
      fontFamily: 'League Spartan',
      fontWeight: 500,
    },
    betaSpan: {
      backgroundColor: palette.primary.purple1,
      color: palette.white,
      padding: '2px 8px',
      borderRadius: '20px',
      position: 'absolute',
      fontSize: 12,
      bottom: '-12px',
      fontFamily: 'Figtree',
    },
  };
});

export const Logo: FunctionComponent<LogoProps> = ({ tagText, color, fontSize = 35 }) => {
  const classes = useStylesFromThemeFunction();

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <p className={classes.logoText} style={{ color: color, fontSize }}>
        mylogger
        {tagText && <span className={classes.betaSpan}>{tagText}</span>}
      </p>
    </div>
  );
};
