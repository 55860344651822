import { Language } from '@my-logger/my-logger.types';
import en from './en';
import fr from './fr';

type Definition = {
  title: string;
  subtitle: string;
  advice: string[];
  seeLogs: string;
  deleteApplicationModal: {
    title: string;
    warning: (name: string) => string;
  };
};

export const myApplications: { [key: string]: Definition } = {
  [Language.English]: en,
  [Language.French]: fr,
};
