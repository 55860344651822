import { FunctionComponent } from 'react';
import { Notification as Rnotification } from 'rsuite';
import { MessageType } from 'rsuite/esm/Notification/Notification';

interface Props {
  title: string;
  message: string;
  type: MessageType;
}

export const Notification: FunctionComponent<Props> = ({ title, message, type }) => {
  return (
    <Rnotification type={type} header={title}>
      <div className="notification-container">{message}</div>
    </Rnotification>
  );
};
