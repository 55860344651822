import { Language } from '@my-logger/my-logger.types';
import en from './en';
import fr from './fr';

type Definition = {
  title: string;
  subtitle: string;
};

export const documentation: { [key: string]: Definition } = {
  [Language.English]: en,
  [Language.French]: fr,
};
