import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import RemindFillIcon from '@rsuite/icons/RemindFill';
import { Input, Modal, Text } from '@my-logger/my-logger.ui';

import { clearApiKey } from '../../../redux/settings/reducer';
import { RootState, useDispatch } from '../../../redux/create-store';
import { translations } from '../../../lang';
import { SettingsState } from '../../../redux/settings/state';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const ApiKeyModal: FunctionComponent<Props> = ({ open, setOpen }) => {
  const { settings, apiKey } = useSelector<RootState, SettingsState>((state) => state.settings);
  const reduxDispatch = useDispatch();

  const T = translations.pages.settings[settings.language].apiKeyModals.confirmationModal;

  const closeModal = () => {
    reduxDispatch(clearApiKey());
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      setOpen={closeModal}
      title={T.title}
      hideCancel
      cancelText="" //TODO: make this prop optional when hideCancel is true
      submitText="Confirm"
      onSubmit={closeModal}
    >
      <Text style={{ fontSize: 16, marginBottom: 12 }}>
        <>
          <RemindFillIcon style={{ color: 'orange', fontSize: 20, marginRight: 8 }} /> {T.text}
        </>
      </Text>
      <Input value={apiKey || ''} style={{ marginBottom: 16 }} readOnly />
    </Modal>
  );
};
