import { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from '../theme-provider';

export interface CardProps {
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    card: {
      display: 'table',
      padding: '16px 32px',
      borderRadius: 5,
      backgroundColor: isDark ? palette.grey.dark.grey02 : palette.white,
      boxShadow: isDark ? '2px 2px 10px rgba(10, 10, 10, 0.25)' : '0px 2px 4px rgba(73, 80, 87, 0.25)',
    },
  };
});

export const Card: FunctionComponent<CardProps> = ({ children, style, className }) => {
  const classes = useStylesFromThemeFunction();
  return (
    <div className={`${classes.card} ${className}`} style={style}>
      {children}
    </div>
  );
};
