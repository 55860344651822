import { FunctionComponent, ReactNode, useMemo } from 'react';
import { ThemeProvider } from '@my-logger/my-logger.ui';
import { Provider, useSelector } from 'react-redux';

import { createStore, RootState } from './redux/create-store';
import { Router } from './Router';

export const ThemeWrapper: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const isDarkTheme = useSelector<RootState, boolean>((state) => state.settings.settings.useDarkMode);
  const isLoading = useSelector<RootState, boolean>((state) => state.app.isLoading);

  return <ThemeProvider theme={!isDarkTheme && !isLoading ? 'light' : 'dark'}>{children}</ThemeProvider>;
};

export const App: FunctionComponent = () => {
  const mainApiUrl = process.env.REACT_APP_MAIN_API_URL || '';
  const store = useMemo(() => createStore({ mainApiUrl }), [mainApiUrl]);

  return (
    <Provider store={store}>
      <ThemeWrapper>
        <Router />
      </ThemeWrapper>
    </Provider>
  );
};
