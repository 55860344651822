import { FunctionComponent, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { Modal as RModal } from 'rsuite';

import { Button } from '../button';
import { Text } from '../text';
import { Theme } from '../theme-provider';

export interface ModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  children?: ReactNode;
  hideCancel?: boolean;
  title: string;
  className?: string;
  onSubmit?: () => void;
  onExited?: () => void;
  onEnter?: () => void;
  cancelText?: string;
  submitText?: string;
}

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    '@global': {
      '.rs-modal-content': {
        backgroundColor: isDark ? palette.grey.dark.grey01 : undefined,
      },
      '.modal-title': {
        color: isDark ? palette.white : undefined,
      },
      '.rs-modal-wrapper': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    bodyContainer: {
      padding: '4px',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  };
});

export const Modal: FunctionComponent<ModalProps> = ({
  open,
  setOpen,
  children,
  hideCancel,
  title,
  className,
  onSubmit,
  onExited,
  onEnter,
  cancelText,
  submitText,
}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <RModal
      open={open}
      onClose={() => setOpen(false)}
      className={`mlg-modal ${className || ''}`}
      onExited={onExited}
      onEnter={onEnter}
    >
      <RModal.Header>
        <RModal.Title>
          <Text variant="h3">{title}</Text>
        </RModal.Title>
      </RModal.Header>
      <RModal.Body className={classes.bodyContainer}>{children}</RModal.Body>
      <RModal.Footer className={classes.footerContainer}>
        {!hideCancel && (
          <Button onClick={() => setOpen(false)} appearance="subtle">
            {cancelText}
          </Button>
        )}
        {onSubmit && (
          <Button onClick={onSubmit} type="submit">
            {submitText}
          </Button>
        )}
      </RModal.Footer>
    </RModal>
  );
};
