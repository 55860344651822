import { FunctionComponent } from 'react';
import { createUseStyles, mobileBreakpoint, Modal, Theme } from '@my-logger/my-logger.ui';
import { Log, UserSettings } from '@my-logger/my-logger.types';
import { useWindowDimensions } from '../../../custom-hooks/use-window-dimensions';
import { translations } from '../../../lang';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/create-store';

interface Props {
  close: () => void;
  log?: Log;
  setApplications: (values: string[]) => void;
}

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { palette, isDark } = theme;

  const commonTag = {
    padding: '5px 10px',
    borderRadius: 5,
    marginRight: 5,
  };

  return {
    modal: {
      width: 800,
      maxWidth: '95%',
    },
    logContainer: {
      backgroundColor: isDark ? palette.grey.dark.grey02 : palette.grey.light.grey06,
      color: isDark ? palette.grey.dark.grey04 : undefined,
      border: `1px solid ${isDark ? palette.grey.dark.grey03 : palette.grey.light.grey04}`,
      borderRadius: 6,
      padding: 20,
      marginBottom: 20,
      height: 200,
      overflowY: 'auto',
    },
    warningTag: {
      backgroundColor: palette.warning.warn02,
      color: palette.warning.warn01,
      ...commonTag,
    },
    errorTag: {
      backgroundColor: palette.error.error02,
      color: palette.error.error01,
      ...commonTag,
    },
    infoTag: {
      backgroundColor: palette.primary.purple1,
      color: palette.white,
      ...commonTag,
    },
    infoContainer: {
      color: isDark ? palette.grey.dark.grey05 : undefined,
    },
  };
});

export const LogDetailsModal: FunctionComponent<Props> = ({ close, log, setApplications }) => {
  const { language } = useSelector<RootState, UserSettings>((state) => state.settings.settings);
  const T = translations.pages.logs[language];

  const classes = useStylesFromThemeFunction();
  const { width } = useWindowDimensions();
  const isMobile = width <= mobileBreakpoint;

  const handleApplicationClick = (): void => {
    if (log) {
      setApplications([log.application]);
      close();
    }
  };

  return (
    <Modal
      open={!!log}
      className={classes.modal}
      setOpen={(val: boolean) => !val && close()}
      title={T.modal.title}
      hideCancel
      onExited={close}
    >
      {log && (
        <div>
          <div className={classes.logContainer}>{log?.message}</div>
          <div className={classes.infoContainer}>
            <span
              className={
                log?.level === 'warning'
                  ? classes.warningTag
                  : log?.level === 'error'
                  ? classes.errorTag
                  : classes.infoTag
              }
            >
              {log?.level}
            </span>
            <span>{` ${T.modal.at} ${log?.creationDate}`}</span>
            {!isMobile ? <span style={{ marginLeft: 10, marginRight: 10 }}> | </span> : <br />}
            <span>
              {isMobile && (
                <p style={{ paddingTop: 12, display: 'inline-block', marginRight: 8 }}>{T.table.application}: </p>
              )}
              <a onClick={handleApplicationClick} style={{ color: '#3d64ff', cursor: 'pointer' }}>
                {log.application}
              </a>
            </span>
          </div>
        </div>
      )}
    </Modal>
  );
};
