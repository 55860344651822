export const palette = {
  primary: {
    purple1: '#9c1dea',
    purple2: 'rgba(156, 29, 234, 0.10)',
  },
  black: '#2a2a2a',
  white: '#ffffff',
  grey: {
    light: {
      grey01: '#575757',
      grey02: '#868686',
      grey03: '#b1b1b1',
      grey04: '#D9D9D9',
      grey05: '#e3e3e3',
      grey06: '#f6f6f6',
    },
    dark: {
      grey01: '#353535',
      grey02: '#3d3d3d',
      grey03: '#464444',
      grey04: '#808080',
      grey05: '#b1b1b1',
      grey06: '#cccccc',
    },
  },
  success: {
    success01: '#36AD57',
    success02: 'rgba(54, 173, 87, 0.15)',
  },
  error: {
    error01: '#ED6E6E',
    error02: 'rgba(237, 110, 110, 0.15)',
  },
  warning: {
    warn01: '#FFB800',
    warn02: 'rgba(255, 184, 0, 0.15)',
  },
};
