import { SerializedError } from '@reduxjs/toolkit';
import { ClientError } from '../types';

export const unexpectedError: ClientError = {
  title: 'Unexpected error',
  message: 'It looks like we are having issues. We are looking into it, please try again later.',
  type: 'error',
};

export const generateErrorNotification = (title: string, error: SerializedError): ClientError => ({
  title,
  message: error.message as string,
  type: 'error',
});

export class ValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ValidationError';
    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}

export class BusinessError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BusinessError';
    Object.setPrototypeOf(this, BusinessError.prototype);
  }
}

export class UnauthorizedError extends Error {
  constructor(message?: string) {
    super(message || 'Unauthorized');
    this.name = 'UnauthorizedError';
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}
