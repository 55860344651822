import { CSSProperties, FunctionComponent, Ref, useState } from 'react';
import { Nav, Sidenav as Navigation } from 'rsuite';
import { createUseStyles } from 'react-jss';
import DoingRoundIcon from '@rsuite/icons/DoingRound';

import { Logo, LogoProps } from '../logo';
import { palette } from '../../theme/palettes';
import { Theme } from '../theme-provider';
import { Text } from '../text';

export interface NavigationMenuProps {
  expandable?: boolean;
  /** Width of the menu when it is expanded. Minimum is 230px */
  width?: number;
  /** Props to customize the logo */
  logoProps?: LogoProps;
  /** Current active item */
  /** Items to be rendered in the menu */
  menuItems: {
    label: string;
    eventKey: string;
    onClick?: () => void;
    icon?: JSX.Element;
  }[];
  /** Item to highlight */
  activeKey?: string;
  /** Software version to display on the footer */
  version?: string;
  /** React ref object */
  ref?: Ref<HTMLDivElement>;
  /** Custom styles to apply */
  style?: CSSProperties;
  /** Option to hide the logo */
  hideLogo?: boolean;
  /** Show placeholders */
  isLoading?: boolean;
}

const useStylesFromThemeFunction = (expandable: boolean) =>
  createUseStyles((theme: Theme) => {
    const { isDark, palette } = theme;

    return {
      '@global': {
        '.rs-sidenav-item, .rs-sidenav-toggle > button': {
          backgroundColor: `${isDark ? palette.black : palette.white} !important`,
          color: isDark ? palette.grey.dark.grey05 : undefined,
        },
        '.rs-sidenav-item': {
          '&:hover': {
            color: isDark ? `${palette.grey.dark.grey05} !important` : undefined,
            backgroundColor: `${palette.primary.purple2} !important`,
          },
        },
        '.sidenav-header .logo-text': {
          color: `${isDark ? palette.white : palette.black} !important`,
        },
        '.rs-sidenav-default .rs-sidenav-toggle': {
          borderTopColor: isDark ? `${palette.grey.dark.grey01} !important` : undefined,
        },
        '.rs-sidenav-item-active': {
          backgroundColor: `${palette.primary.purple1} !important`,
          color: `${palette.white} !important`,
          '&:hover': {
            backgroundColor: `${palette.primary.purple1} !important`,
          },
        },
        '.rs-sidenav-default, .rs-sidenav-item, .rs-sidenav-toggle-button': {
          transition: 'color 0.1s linear, background-color 0.1s linear, width 0.1s linear !important',
        },
        '.fake-mobile-logo': {
          marginTop: '16px',
          fontSize: '25px',
        },
        '.rs-sidenav-item-icon': {
          marginTop: '3px',
        },
      },
      sidenav: {
        height: '100%',
        backgroundColor: `${isDark ? palette.black : palette.white} !important`,
        boxShadow: expandable
          ? isDark
            ? '5px 5px 15px 5px rgba(20, 20, 20, 0.1)'
            : '5px 5px 15px 5px rgba(126, 126, 126, 0.1)'
          : undefined,
        color: isDark ? palette.grey.dark.grey05 : undefined,
      },
      versionContainer: {
        position: 'absolute',
        bottom: 15,
        left: 10,
        fontSize: 12,
        color: isDark ? palette.grey.dark.grey05 : palette.grey.light.grey03,
      },
    };
  });

export const SideNavigation: FunctionComponent<NavigationMenuProps> = ({
  menuItems,
  logoProps,
  width = 300,
  activeKey,
  expandable = true,
  version,
  ref,
  style,
  isLoading = false,
  hideLogo,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  if (width < 230) width = 230;

  const handleClick = (index: number): void => {
    const item = menuItems[index];

    if (item.onClick) {
      item.onClick();
    }
  };

  const classes = useStylesFromThemeFunction(expandable)();

  return (
    <Navigation
      expanded={isExpanded}
      className={classes.sidenav}
      as="div"
      ref={ref}
      style={{
        position: 'relative',
        height: '100%',
        width: expandable && isExpanded ? width : undefined,
        ...style,
      }}
    >
      <Text variant="label" className={classes.versionContainer}>
        {version}
      </Text>
      {!hideLogo && (
        <div className="sidenav-header" style={{ padding: isExpanded ? 16 : undefined, height: 100 }}>
          {isExpanded ? (
            <Logo {...logoProps} />
          ) : (
            <div style={{ textAlign: 'center' }}>
              <DoingRoundIcon className="fake-mobile-logo" color={palette.primary.purple1} />
            </div>
          )}
        </div>
      )}
      <Navigation.Body style={{ marginTop: 24 }}>
        <Nav activeKey={isLoading ? undefined : activeKey}>
          {menuItems.map((item, index) => {
            const isActive = activeKey === item.eventKey;

            return (
              <Nav.Item
                key={item.label}
                eventKey={item.eventKey}
                disabled={isLoading}
                icon={item.icon}
                style={{ color: isActive ? 'palette.white' : undefined }}
                onClick={isLoading ? undefined : () => handleClick(index)}
              >
                <Text
                  isLoading={isLoading}
                  loadingMaxLength={180}
                  style={{ color: 'inherit', marginBottom: 0, position: 'relative', top: isLoading ? 4 : 2 }}
                >
                  {item.label}
                </Text>
              </Nav.Item>
            );
          })}
        </Nav>
      </Navigation.Body>
      {expandable && <Navigation.Toggle onToggle={(expanded) => setIsExpanded(expanded)}></Navigation.Toggle>}
    </Navigation>
  );
};
