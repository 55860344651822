import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Log, PaginationFilters, SearchFilters } from '@my-logger/my-logger.types';

import { MainAPIClient } from '../../services/main-api-client';
import { unexpectedError } from '../../utils/errors';
import { initialState } from './state';

export const fetchLogs = createAsyncThunk<
  { logs: Log[]; totalResults: number; page: number },
  {
    userId: number;
    pagination: PaginationFilters;
    filters: SearchFilters;
  },
  { extra: { mainApiClient: MainAPIClient } }
>('logs/get', async ({ userId, pagination, filters }, thunkAPI) => {
  const { mainApiClient } = thunkAPI.extra;
  const { page } = pagination;

  thunkAPI.dispatch(logsSlice.actions.startLoading());

  const { results } = await mainApiClient.getLogs({ userId, pagination, filters });
  const { data: logs, totalResults } = results;

  return { logs, totalResults, page };
});

const logsSlice = createSlice({
  name: 'apiKey',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;

      const { logs, totalResults, page } = action.payload;

      if (page === 1) {
        state.logs = logs;
        state.totalLogs = totalResults;
      } else {
        state.logs = [...state.logs, ...logs];
      }
    });
    builder.addCase(fetchLogs.rejected, (state) => {
      state.isLoading = false;
      state.error = unexpectedError;
    });
  },
});

export const logsReducer = logsSlice.reducer;
