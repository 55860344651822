import { FormEvent, FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { toaster } from 'rsuite';
import { Modal, CircleLoader, Input, Text } from '@my-logger/my-logger.ui';

import { Notification } from '../../../components/notification';
import { generateApikey } from '../../../redux/settings/reducer';
import { RootState, useDispatch } from '../../../redux/create-store';
import { ClientError } from '../../../types';
import { SettingsState } from '../../../redux/settings/state';
import { translations } from '../../../lang';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const EnterPasswordModal: FunctionComponent<Props> = ({ open, setOpen }) => {
  const reduxDispatch = useDispatch();
  const { settings, isLoading } = useSelector<RootState, SettingsState>((state) => state.settings);

  const [password, setPassword] = useState<string>('');
  const T = translations.pages.settings[settings.language].apiKeyModals.passwordModal;

  const onSubmit = (event?: FormEvent<HTMLFormElement>): void => {
    if (event) {
      event.preventDefault();
    }
    if (password === '') {
      const error: ClientError | null = {
        ...translations.errors[settings.language].missingPassword,
        type: 'error',
      };
      toaster.push(Notification(error), { placement: 'topEnd' });
    } else {
      reduxDispatch(generateApikey({ password }));
    }
  };

  return (
    <Modal open={open} setOpen={setOpen} title={T.title} onSubmit={onSubmit} cancelText="Cancel" submitText="Confirm">
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircleLoader />
        </div>
      ) : (
        <>
          <Text style={{ fontSize: 16, marginBottom: 12 }}>{T.text}</Text>
          <form onSubmit={onSubmit}>
            <Input value={password} onChange={setPassword} isPassword style={{ marginBottom: 16 }} />
          </form>
        </>
      )}
    </Modal>
  );
};
