import { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { Divider } from 'rsuite';
import { Text } from '../text';
import { Placeholder } from '../placeholder';
import { Theme } from '../theme-provider';

export interface ListProps {
  /** Items to be rendered in the list */
  listItems: ListItem[];
  /** Show placeholders */
  isLoading?: boolean;
}

export interface ListItem {
  iconColor: string;
  name: string;
  description?: string;
  previewNumber?: number;
  handleEdit?: () => void;
  handleDelete?: () => void;
}

const formatNumber = (logsCount: number): string => {
  const unit =
    Math.abs(logsCount) >= 1.0e9 ? 'B' : Math.abs(logsCount) >= 1.0e6 ? 'M' : Math.abs(logsCount) >= 1.0e3 ? 'K' : '';
  const numberDevided =
    Math.abs(logsCount) >= 1.0e9
      ? Math.abs(logsCount) / 1.0e9
      : Math.abs(logsCount) >= 1.0e6
      ? Math.abs(logsCount) / 1.0e6
      : Math.abs(logsCount) >= 1.0e3
      ? Math.abs(logsCount) / 1.0e3
      : Math.abs(logsCount);
  return `${(Math.round(numberDevided * 10) / 10).toFixed(1).replace('.0', '')}${unit}`;
};

const useStylesFromThemeFunction = (isLoading: boolean) =>
  createUseStyles((theme: Theme) => {
    const { isDark, palette } = theme;

    return {
      '@global': {
        '.rs-placeholder-active.rs-placeholder-graph, .rs-placeholder-active .rs-placeholder-paragraph-graph-image, .rs-placeholder-active .rs-placeholder-grid-col > p, .rs-placeholder-active .rs-placeholder-paragraph-rows > p':
          {
            background: isDark ? 'linear-gradient(-45deg, #3e3e3e 25%, #505050 37%, #474747 100%)' : undefined,
          },
      },
      container: {
        backgroundColor: isDark ? palette.grey.dark.grey02 : palette.white,
        display: 'flex',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        padding: 15,
        marginBottom: 12,
        transition: 'background-color 0.2s ease-in-out',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 5px 0px',

        '&:hover': {
          cursor: 'pointer',
          backgroundColor: isDark ? palette.grey.dark.grey03 : palette.grey.light.grey05,
        },
      },
      previewLogo: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: palette.black,
        flex: '0 0 auto',
        width: 40,
        height: 40,
        fontSize: 20,
        borderRadius: '100%',
        textTransform: 'uppercase',
        marginRight: 16,
      },
      listItemName: {
        color: isDark ? palette.white : palette.black,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        textAlign: 'left',
        marginBottom: isLoading ? undefined : 0,
      },
      listItemDescription: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        textAlign: 'left',
        color: isDark ? palette.grey.dark.grey04 : palette.grey.light.grey02,
        marginTop: 0,
      },
      itemInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginRight: 15,
      },
      previewNumberContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',

        '& p': {
          margin: 'unset',
        },
      },
      previewNumber: {
        color: isDark ? palette.white : palette.black,
      },
      listItemRightContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
      },
      verticalDivider: {
        backgroundColor: isDark ? palette.grey.dark.grey03 : palette.grey.light.grey05,
        height: 22,
      },
      previewText: {
        color: isDark ? palette.grey.dark.grey04 : palette.grey.light.grey03,
      },
      listItemLinksContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',

        '& a': {
          color: 'grey',

          '&:hover': {
            color: palette.primary.purple1,
          },
        },
      },
    };
  });

export const List: FunctionComponent<ListProps> = ({ listItems, isLoading }) => {
  const classes = useStylesFromThemeFunction(isLoading || false)();

  return (
    <div className="my-logger-list">
      {(isLoading ? Array(3).fill({} as never) : listItems).map(
        ({ name, iconColor, description, previewNumber, handleDelete, handleEdit }, index) => (
          <div key={`${name}-${index}`}>
            <div className={classes.container}>
              {isLoading ? (
                <Placeholder active className={classes.previewLogo} width={40} height={40} rounded={false} />
              ) : (
                <div className={classes.previewLogo} style={{ backgroundColor: isLoading ? undefined : iconColor }}>
                  {name[0]}
                </div>
              )}
              <div className={classes.itemInfoContainer}>
                {}
                <Text isLoading={isLoading} className={classes.listItemName}>
                  {name}
                </Text>
                <Text isLoading={isLoading} variant="small" className={classes.listItemDescription}>
                  {description}
                </Text>
              </div>
              <div className={classes.listItemRightContainer}>
                {previewNumber && (
                  <div className={classes.previewNumberContainer}>
                    <Text variant="small" className={classes.previewNumber}>
                      {formatNumber(previewNumber)}
                    </Text>
                    <Text variant="small" className={classes.previewText}>
                      Logs
                    </Text>
                  </div>
                )}
                {(handleEdit || handleDelete) && (
                  <>
                    <Divider vertical className={classes.verticalDivider} />
                    <div className={classes.listItemLinksContainer}>
                      {handleEdit && (
                        <a onClick={handleEdit}>
                          <Text variant="small">Edit</Text>
                        </a>
                      )}
                      {handleDelete && (
                        <a onClick={handleDelete}>
                          <Text variant="small">Delete</Text>
                        </a>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ),
      )}
    </div>
  );
};
