import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toaster } from 'rsuite';
import { Button, Select, Text, Toggle } from '@my-logger/my-logger.ui';
import { Language } from '@my-logger/my-logger.types';

import { Notification } from '../../components/notification';
import { clearError, clearStatus, updateUserSettings } from '../../redux/settings/reducer';
import { SettingsState } from '../../redux/settings/state';
import { RootState, useDispatch } from '../../redux/create-store';
import { ApiKeyModal } from './modals/api-key-modal';
import { EnterPasswordModal } from './modals/enter-password-modal';
import { translations } from '../../lang';
import { PageHeader } from '../../components/page-header';

import './style.less';

export const Settings: FunctionComponent = () => {
  const reduxDispatch = useDispatch();
  const { apiKey, error, settings, isLoading, status } = useSelector<RootState, SettingsState>(
    (state) => state.settings,
  );
  const isAppLoading = useSelector<RootState, boolean>((state) => state.app.isLoading);

  const [openAPIKeyModal, setOpenAPIKeyModal] = useState<boolean>(false);
  const [useDarkMode, setUseDarkMode] = useState<boolean>(settings.useDarkMode);
  const [highlightWarnings, setHighlightWarnings] = useState<boolean>(settings.areWarningsHighlighted);
  const [highlightErrors, setHighlightErrors] = useState<boolean>(settings.areErrorsHighlighted);
  const [languageSwitch, setLanguageSwitch] = useState<Language>(settings.language);

  useEffect(() => {
    setUseDarkMode(settings.useDarkMode);
    setHighlightWarnings(settings.areWarningsHighlighted);
    setHighlightErrors(settings.areErrorsHighlighted);
    setLanguageSwitch(settings.language);
  }, [settings]);

  const T = translations.pages.settings[settings.language];

  const languagesOptions = [Language.English, Language.French].map((item) => ({
    label: item === Language.English ? 'English' : 'Français',
    value: item,
  }));

  useEffect(() => {
    if (error) {
      toaster.push(Notification(error), { placement: 'topEnd' });
      reduxDispatch(clearError());
    }
  }, [error]);

  const onSubmit = (): void => {
    reduxDispatch(
      updateUserSettings({
        settings: {
          useDarkMode,
          areErrorsHighlighted: highlightErrors,
          areWarningsHighlighted: highlightWarnings,
          language: languageSwitch,
        },
      }),
    );
  };

  return (
    <div className="page-container">
      <PageHeader title={T.title} subtitle={T.subTitle} isLoading={isAppLoading} />
      <div className="settings-container">
        <div className="settings-row">
          <Text isLoading={isAppLoading} variant="small">
            {T.darkModeLabel}
          </Text>
          <Toggle disabled={isAppLoading} value={useDarkMode} setValue={setUseDarkMode} />
        </div>
        <div className="settings-row">
          <Text isLoading={isAppLoading} variant="small">
            {T.languageLabel}
          </Text>
          <Select disabled={isAppLoading} data={languagesOptions} value={languageSwitch} onChange={setLanguageSwitch} />
        </div>
        <div className="settings-row">
          <Text isLoading={isAppLoading} variant="small">
            {T.highlightErrorsLabel}
          </Text>
          <Toggle disabled={isAppLoading} value={highlightErrors} setValue={setHighlightErrors} />
        </div>
        <div className="settings-row">
          <Text isLoading={isAppLoading} variant="small">
            {T.highlightWarningLabel}
          </Text>
          <Toggle disabled={isAppLoading} value={highlightWarnings} setValue={setHighlightWarnings} />
        </div>
        <div className="settings-row">
          <Text isLoading={isAppLoading} variant="small">
            {T.apiKeyLabel}
          </Text>
          <Button disabled={isAppLoading} loading={isAppLoading} onClick={() => setOpenAPIKeyModal(true)}>
            {T.apiKeyButtonText}
          </Button>
        </div>
        {!isAppLoading &&
          (apiKey === null ? (
            <EnterPasswordModal open={openAPIKeyModal} setOpen={setOpenAPIKeyModal} />
          ) : (
            <ApiKeyModal open={openAPIKeyModal} setOpen={setOpenAPIKeyModal} />
          ))}
        <div className="buttons-containers">
          <Button
            disabled={isAppLoading || isLoading}
            style={{ width: 100 }}
            onClick={onSubmit}
            loading={isAppLoading || isLoading}
            status={status}
            clearStatus={() => reduxDispatch(clearStatus())}
          >
            {T.saveButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
