import React, { FunctionComponent } from 'react';
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom';

import { PrivateWrapper } from './components/private-wrapper';
import { LogIn } from './pages/authentication/log-in';
import { SignUp } from './pages/authentication/sign-up';
import { AppContainer } from './components/app-container';
import { Dashboard } from './pages/dashboard';
import { Documentation } from './pages/documentation';
import { MyApplications } from './pages/my-applications';
import { Settings } from './pages/settings';
import { Feedback } from './pages/feedback';
import { Logs } from './pages/logs';
import { Pages } from './types';

export const Router: FunctionComponent = () => {
  const buildProtectedRouteInfo = (page: Pages, pageComponent: React.ReactNode): RouteObject => ({
    path: page,
    element: (
      <PrivateWrapper>
        <AppContainer>{pageComponent}</AppContainer>
      </PrivateWrapper>
    ),
  });

  const router = createBrowserRouter([
    {
      path: Pages.SignUp,
      element: <SignUp />,
    },
    {
      path: Pages.Login,
      element: <LogIn />,
    },
    buildProtectedRouteInfo(Pages.MyApplications, <MyApplications />),
    buildProtectedRouteInfo(Pages.Dashboard, <Dashboard />),
    buildProtectedRouteInfo(Pages.Logs, <Logs />),
    buildProtectedRouteInfo(Pages.Documentation, <Documentation />),
    buildProtectedRouteInfo(Pages.Settings, <Settings />),
    buildProtectedRouteInfo(Pages.Feedback, <Feedback />),
    {
      path: '/',
      element: <Navigate to={Pages.Dashboard} />,
    },
  ]);

  return <RouterProvider router={router} />;
};
