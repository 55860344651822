import { Language } from '@my-logger/my-logger.types';
import en from './en';
import fr from './fr';

export type Definition = {
  title: string;
  subtitle: string;
  table: {
    date: string;
    application: string;
    level: string;
    message: string;
    emptyText: string;
    errorText: string;
  };
  filters: {
    liveLogs: string;
    datePickerPlaceholder: string;
    loadingMore: string;
    all: {
      masculine: string;
      feminine: string;
    };
    searchPlaceholder: string;
    lastNthMins: (nb: number) => string;
    lastNthDays: (nb: number) => string;
    lastNthHours: (nb: number) => string;
  };
  modal: {
    title: string;
    at: string;
  };
};

export const logs: { [key: string]: Definition } = {
  [Language.English]: en,
  [Language.French]: fr,
};
