import { User } from '@my-logger/my-logger.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { MainAPIClient } from '../../services/main-api-client';
import { unexpectedError } from '../../utils/errors';
import { initialState } from './state';

export const getUserData = createAsyncThunk<User, void, { extra: { mainApiClient: MainAPIClient } }>(
  'user/get-data',
  async (_, thunkAPI) => {
    const { mainApiClient } = thunkAPI.extra;
    thunkAPI.dispatch(userSlice.actions.startLoading());
    return await mainApiClient.getUserData();
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userId = action.payload.id;
    });
    builder.addCase(getUserData.rejected, (state) => {
      state.isLoading = false;
      state.error = unexpectedError;
    });
  },
});

export const userReducer = userSlice.reducer;
