import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { UserSettings } from '@my-logger/my-logger.types';

import { translations } from '../../lang';
import { RootState } from '../../redux/create-store';
import { PageHeader } from '../../components/page-header';

export const Feedback: FunctionComponent = () => {
  const { language } = useSelector<RootState, UserSettings>((state) => state.settings.settings);
  const isLoading = useSelector<RootState, boolean>((state) => state.app.isLoading);

  const T = translations.pages.feedback[language];

  return (
    <div className="page-container">
      <PageHeader isLoading={isLoading} title={T.title} subtitle={T.subtitle} />
    </div>
  );
};
