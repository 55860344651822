import { AxiosInstance } from 'axios';
import { Application } from '@my-logger/my-logger.types';

export const editUserApplicationsFactory =
  ({ mainApiClient }: { mainApiClient: AxiosInstance }) =>
  async ({
    userId,
    applicationId,
    application,
  }: {
    userId: number;
    applicationId: number;
    application: Pick<Application, 'name' | 'description'>;
  }): Promise<void> => {
    await mainApiClient.patch(`/api/user/${userId}/application/${applicationId}`, application);
  };
