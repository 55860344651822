import { FunctionComponent, UIEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Application, Log, LogLevel, UserSettings } from '@my-logger/my-logger.types';
import {
  CheckPicker,
  createUseStyles,
  DateRangePicker,
  Table,
  TableColumn,
  Input,
  palette,
  Theme,
  mobileBreakpoint,
} from '@my-logger/my-logger.ui';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subMinutes from 'date-fns/subMinutes';
import moment from 'moment';

import { translations } from '../../lang';
import { Definition } from '../../lang/pages/logs';
import { RootState, useDispatch } from '../../redux/create-store';
import { PageHeader } from '../../components/page-header';
import { LogDetailsModal } from './log-details-modal';
import { useWindowDimensions, useDebounce } from '../../custom-hooks';
import { LogsState } from '../../redux/logs/state';
import { fetchLogs } from '../../redux/logs/reducer';
import { LogsError } from './logs-error';
import { EmptyTable } from './empty-table';

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { palette, isDark } = theme;

  return {
    selectedItem: {
      color: isDark ? palette.white : palette.black,
    },
    mainContainer: {
      marginTop: 50,
      width: '100%',
      [`@media screen and (max-width: ${mobileBreakpoint}px)`]: {
        height: '100vh',
      },
    },
    filtersContainer: {
      marginBottom: 30,
      [`@media screen and (max-width: ${mobileBreakpoint}px)`]: {
        flexDirection: 'column',
        display: 'flex',
      },
    },
    warningLog: {
      backgroundColor: palette.warning.warn02,
    },
    errorLog: {
      backgroundColor: palette.error.error02,
    },
    logsContainer: {
      backgroundColor: isDark ? palette.grey.dark.grey01 : palette.grey.light.grey06,
      border: `1px solid ${isDark ? palette.grey.dark.grey03 : palette.grey.light.grey04}`,
      borderRadius: 8,
      marginBottom: 20,
      [`@media screen and (max-width: ${mobileBreakpoint}px)`]: {
        marginTop: 30,
      },
    },
  };
});

const predefinedRanges = (T: Definition) => [
  {
    label: T.filters.lastNthMins(30),
    value: [subMinutes(new Date(), 30), new Date()] as [Date, Date],
    placement: 'left' as const,
  },
  {
    label: T.filters.lastNthHours(1),
    value: [subHours(new Date(), 1), new Date()] as [Date, Date],
    placement: 'left' as const,
  },
  {
    label: T.filters.lastNthHours(2),
    value: [subHours(new Date(), 2), new Date()] as [Date, Date],
    placement: 'left' as const,
  },
  {
    label: T.filters.lastNthHours(24),
    value: [subDays(new Date(), 1), new Date()] as [Date, Date],
    placement: 'left' as const,
  },
  {
    label: T.filters.lastNthDays(2),
    value: [subDays(new Date(), 2), new Date()] as [Date, Date],
    placement: 'left' as const,
  },
  {
    label: T.filters.lastNthDays(7),
    value: [subDays(new Date(), 7), new Date()] as [Date, Date],
    placement: 'left' as const,
  },
];

const getColumns = (isMobile: boolean, T: Definition, isDark: boolean): TableColumn[] => [
  {
    dataKey: 'creationDate',
    columnName: T.table.date,
    width: 200,
  },
  {
    dataKey: 'application',
    columnName: T.table.application,
    width: 150,
  },
  {
    dataKey: 'level',
    columnName: T.table.level,
    width: 100,
  },
  {
    dataKey: 'message',
    color: isDark ? palette.white : palette.black,
    columnName: T.table.message,
    width: isMobile ? 400 : 200,
    flexGrow: isMobile ? undefined : true,
  },
];

export const Logs: FunctionComponent = () => {
  const { language, areErrorsHighlighted, areWarningsHighlighted, useDarkMode } = useSelector<RootState, UserSettings>(
    (state) => state.settings.settings,
  );
  const userId = useSelector<RootState, number>((state) => state.user.userId as number);
  const userApps = useSelector<RootState, Application[]>((state) => state.applications.applications);
  const isAppLoading = useSelector<RootState, boolean>((state) => state.app.isLoading);
  const { isLoading, logs, totalLogs, error } = useSelector<RootState, LogsState>((state) => state.logs);

  const dispatch = useDispatch();

  const levelsOptions = [
    { label: LogLevel.Info, value: LogLevel.Info },
    { label: LogLevel.Warning, value: LogLevel.Warning },
    { label: LogLevel.Error, value: LogLevel.Error },
  ];
  const [appOptions, setAppOptions] = useState<{ label: string; value: string }[]>([]);

  const T = translations.pages.logs[language];

  const [search, setSearch] = useState<string | undefined>();
  const debouncedSearch = useDebounce<typeof search>(search, 500);

  const [levels, setLevels] = useState<LogLevel[]>([LogLevel.Error, LogLevel.Info, LogLevel.Warning]);
  const [dates, setDates] = useState<[Date, Date] | null | undefined>();
  const [applications, setApplications] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [previewLog, setPreviewLog] = useState<Log | undefined>();
  const searchBarRef = useRef<HTMLDivElement | null>(null);
  const shouldLoadInitial = useRef<boolean>(true);

  const { width } = useWindowDimensions();

  const isMobile = useMemo(() => width <= mobileBreakpoint, [width]);
  const columns = useMemo(() => getColumns(isMobile, T, useDarkMode), [isMobile, T, useDarkMode]);

  const toUTC = (date: Date): Date => moment(date).utc(true).toDate();

  const triggerSearch = async (pPage?: number) => {
    const [startDate, endDate] = dates || [];

    await dispatch(
      fetchLogs({
        userId,
        pagination: { page: pPage || page, pageSize: 50 },
        filters: {
          search: debouncedSearch,
          dates: dates && startDate && endDate ? [toUTC(startDate), toUTC(endDate)] : undefined,
          applications,
          levels,
        },
      }),
    );
    setShowLoader(false);
  };

  const loadMore = () => {
    if (page === 1 || totalLogs > logs.length) {
      triggerSearch();
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (userId && applications.length) {
      if (shouldLoadInitial.current) {
        //Load initial logs
        loadMore();
        shouldLoadInitial.current = false;
      } else {
        //Trigger search and go back to first page
        setShowLoader(true);
        triggerSearch(1);
        setPage(2);
      }
    }
  }, [userId, applications, dates, levels, debouncedSearch]);

  //Set applications in the select picker once they are loaded
  useEffect(() => {
    if (userApps.length) {
      setAppOptions(userApps.map((option) => ({ label: option.name, value: option.name })));
      setApplications(userApps.map((app) => app.name));
    }
  }, [userApps]);

  const handleScroll = (event: UIEvent<HTMLDivElement>): void => {
    if (
      (event.target as HTMLElement).scrollTop ===
      (event.target as HTMLElement).scrollHeight - (event.target as HTMLElement).offsetHeight
    ) {
      loadMore();
    }
  };

  const classes = useStylesFromThemeFunction();

  const handleRowClass = (rowData: any): string => {
    if (!rowData) return '';
    return rowData['level'] === 'warning' && areWarningsHighlighted
      ? classes.warningLog
      : rowData['level'] === 'error' && areErrorsHighlighted
      ? classes.errorLog
      : '';
  };

  return (
    <div className="page-container" onScroll={handleScroll} style={{ paddingBottom: 20 }}>
      <LogDetailsModal log={previewLog} close={() => setPreviewLog(undefined)} setApplications={setApplications} />
      <PageHeader isLoading={isAppLoading} title={T.title} subtitle={T.subtitle} />
      <div className={classes.mainContainer}>
        <Input value={search || ''} onChange={setSearch} ref={searchBarRef} placeholder={T.filters.searchPlaceholder} />
        <div className={classes.filtersContainer}>
          <DateRangePicker
            value={dates}
            onChange={setDates}
            character=" / "
            placeholder={T.filters.datePickerPlaceholder}
            color={palette.black}
            style={{ marginRight: 10, width: isMobile ? '100%' : 300, marginTop: 15 }}
            format="yyyy-MM-dd HH:mm"
            ranges={predefinedRanges(T)}
          />
          <CheckPicker
            data={appOptions}
            values={applications}
            onChange={setApplications}
            label={T.table.application}
            renderValue={
              applications.length === appOptions.length
                ? () => <p className={classes.selectedItem}>{T.filters.all.feminine}</p>
                : undefined
            }
            style={{ width: isMobile ? '100%' : 350, marginRight: 10, marginTop: 15 }}
          />
          <CheckPicker
            data={levelsOptions}
            values={levels}
            onChange={setLevels as (val: string[]) => void}
            renderValue={
              levels.length === levelsOptions.length
                ? () => <p className={classes.selectedItem}>{T.filters.all.masculine}</p>
                : undefined
            }
            label={T.table.level}
            style={{ width: isMobile ? '100%' : 200, marginRight: 10, marginTop: 15 }}
            hideSearch
          />
        </div>
        {error && <LogsError text={T.table.errorText} />}
        <div className={classes.logsContainer}>
          {!isAppLoading && (
            <Table
              data={showLoader ? [] : logs}
              columns={columns}
              loadingMoreText={T.filters.loadingMore}
              loading={isLoading || showLoader}
              renderEmpty={() => <EmptyTable text={T.table.emptyText} />}
              rowClassName={handleRowClass}
              onItemClick={setPreviewLog}
            />
          )}
        </div>
      </div>
    </div>
  );
};
