import { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { Placeholder as RPlaceholder, PlaceholderGraphProps } from 'rsuite';
import { Theme } from '../theme-provider';

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark } = theme;

  return {
    '@global': {
      '.rs-placeholder-active.rs-placeholder-graph, .rs-placeholder-active .rs-placeholder-paragraph-graph-image, .rs-placeholder-active .rs-placeholder-grid-col > p, .rs-placeholder-active .rs-placeholder-paragraph-rows > p':
        {
          background: isDark ? 'linear-gradient(-45deg, #3e3e3e 25%, #505050 37%, #474747 100%)' : undefined,
        },
    },
  };
});

export interface PlaceholderProps extends PlaceholderGraphProps {
  rounded?: boolean;
}

export const Placeholder: FunctionComponent<PlaceholderProps> = ({ rounded = true, ...rest }) => {
  useStylesFromThemeFunction();
  return <RPlaceholder.Graph {...rest} style={{ ...rest.style, borderRadius: rounded ? 3 : undefined }} />;
};
