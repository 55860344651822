import { FormEvent, FunctionComponent, KeyboardEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircleLoader, Modal, Text } from '@my-logger/my-logger.ui';
import { Application, UserSettings } from '@my-logger/my-logger.types';

import { RootState, useDispatch } from '../../../redux/create-store';
import { translations } from '../../../lang';
import { UserApplicationState } from '../../../redux/applications/state';
import { deleteApplication } from '../../../redux/applications/reducer';
import { UserState } from '../../../redux/user/state';

import './style.less';

interface Props {
  setOpen: (value: boolean) => void;
  application?: Application;
}

export const DeleteApplicationModal: FunctionComponent<Props> = ({ setOpen, application }) => {
  const { language } = useSelector<RootState, UserSettings>((state) => state.settings.settings);
  const T = translations.pages.myApplications[language].deleteApplicationModal;

  const { isLoading } = useSelector<RootState, UserApplicationState>((state) => state.applications);
  const { userId } = useSelector<RootState, UserState>((state) => state.user);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const reduxDispatch = useDispatch();

  const onSubmit = async (event?: FormEvent<HTMLFormElement>): Promise<void> => {
    if (event) {
      event.preventDefault();
    }
    if (userId && application?.id) {
      await reduxDispatch(deleteApplication({ userId, applicationId: application.id }));
    }
  };

  const onKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  useEffect(() => {
    if (isLoading) {
      setShowLoader(true);
    }
  }, [isLoading]);

  return (
    <Modal
      open={!!application}
      setOpen={setOpen}
      title={T.title}
      onSubmit={onSubmit}
      cancelText="Cancel"
      submitText="Confirm"
      className="delete-application-modal"
      onExited={() => {
        setShowLoader(false);
      }}
      onEnter={() => setShowLoader(false)}
    >
      {showLoader ? (
        <div className="circle-loader-container">
          <CircleLoader />
        </div>
      ) : (
        <>
          <Text style={{ marginBottom: 20 }}>{T.warning(application?.name || '')}</Text>
          <form onKeyDown={onKeyDown}></form>
        </>
      )}
    </Modal>
  );
};
