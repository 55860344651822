import { ClientError } from '../../types';
import { Application } from '@my-logger/my-logger.types';

export type UserApplicationState = {
  isLoading: boolean;
  applications: Application[];
  error?: ClientError;
};

export const initialState: UserApplicationState = {
  isLoading: false,
  applications: [],
};
