import { createContext, FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { ThemeProvider as JSSThemeProvider } from 'react-jss';
import { palette } from '../../theme/palettes';

import '../../theme/custom-theme.less';

type Themes = 'dark' | 'light';

export interface Theme {
  isDark: boolean;
  palette: typeof palette;
}

export const ThemeContext = createContext({
  theme: 'dark',
  //eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: (_value: Themes): void => {},
});

interface ThemeProviderProps {
  theme: Themes;
  children: ReactNode;
}

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({ children, theme }) => {
  const [currentTheme, setTheme] = useState<Themes>(theme);

  useEffect(() => {
    setTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, setTheme }}>
      <JSSThemeProvider theme={{ isDark: theme === 'dark', palette }}>{children}</JSSThemeProvider>
    </ThemeContext.Provider>
  );
};
