import { palette } from '@my-logger/my-logger.ui';
import { FunctionComponent } from 'react';

export const LogsError: FunctionComponent<{ text: string }> = ({ text }) => {
  return (
    <div
      style={{
        color: palette.error.error01,
        backgroundColor: palette.error.error02,
        padding: 10,
        marginBottom: 20,
        borderRadius: 5,
        boxShadow: '0px 0px 15px -3px rgba(0,0,0,0.1)',
      }}
    >
      {text}
    </div>
  );
};
