import { FunctionComponent } from 'react';
import { CheckPicker as RCheckPicker, CheckPickerProps as RCheckPickerProps } from 'rsuite';
import { createUseStyles } from 'react-jss';
import { Theme } from '../theme-provider';

export interface CheckPickerProps extends RCheckPickerProps<string> {
  /** Override styles */
  style?: React.CSSProperties;
  /** ClassName */
  className?: string;
  /** Options to show */
  data: { value: string; label: string }[];
  /** Input should have type password */
  values: string[];
  /** Method triggered on change */
  onChange: (value: string[]) => void;
  /** Input label */
  label?: string;
  /** Hide search input */
  hideSearch?: boolean;
}

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    input: {
      '@global': {
        '.rs-picker-toggle': {
          backgroundColor: isDark ? 'inherit !important' : undefined,
          color: isDark ? 'inherit !important' : undefined,
          border: isDark ? 'unset' : undefined,
          borderRadius: isDark ? 'inherit !important' : undefined,
        },
        '.rs-picker-toggle-label': {
          color: isDark ? palette.grey.dark.grey05 : undefined,
        },
        '.rs-picker-value-list': {
          color: isDark ? palette.white : undefined,
        },
      },
      backgroundColor: isDark ? `${palette.grey.dark.grey02} !important` : undefined,
      color: isDark ? `${palette.grey.dark.grey05} !important` : undefined,
      border: isDark ? `1px solid ${palette.grey.dark.grey03} !important` : undefined,
      borderRadius: 6,
    },
    menu: {
      backgroundColor: isDark ? palette.grey.dark.grey01 : undefined,
      '@global': {
        '.rs-picker-search-bar-input': {
          backgroundColor: isDark ? `${palette.grey.dark.grey02} !important` : undefined,
          border: isDark ? `1px solid ${palette.grey.dark.grey03} !important` : undefined,
        },
        '.rs-checkbox-checker': {
          color: isDark ? `${palette.grey.dark.grey05} !important` : undefined,
        },
      },
    },
  };
});

export const CheckPicker: FunctionComponent<CheckPickerProps> = ({
  style,
  data,
  values,
  hideSearch,
  onChange,
  className,
  label,
  ...rest
}) => {
  const classes = useStylesFromThemeFunction();

  const internalHandleChange = (newValues: string[]): void => {
    if (newValues.length) {
      onChange(newValues);
    } else {
      //When clearing data, all options will be selected
      onChange(data.map((item) => item.value));
    }
  };

  return (
    <RCheckPicker
      style={style}
      label={label}
      menuClassName={classes.menu}
      sticky
      countable={values.length !== data.length}
      cleanable={values.length !== data.length}
      searchable={!hideSearch}
      value={values}
      className={`${classes.input} ${className}`}
      data={data}
      onChange={internalHandleChange}
      {...rest}
    />
  );
};
