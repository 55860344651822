import { Log } from '@my-logger/my-logger.types';
import { ClientError, SaveButtonStatus } from '../../types';

export type LogsState = {
  status?: SaveButtonStatus;
  isLoading: boolean;
  error?: ClientError;
  totalLogs: number;
  logs: Log[];
};

export const initialState: LogsState = {
  isLoading: false,
  totalLogs: 0,
  logs: [],
};
