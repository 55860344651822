import { Language } from '@my-logger/my-logger.types';
import en from './en';
import fr from './fr';

type ErrorDefinition = {
  title: string;
  message: string;
};

type Definition = {
  missingPassword: ErrorDefinition;
};

export const errors: { [key: string]: Definition } = {
  [Language.English]: en,
  [Language.French]: fr,
};
