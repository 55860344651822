import { Language } from '@my-logger/my-logger.types';
import en from './en';
import fr from './fr';

type Definition = {
  cancel: string;
  done: string;
};

export const modal: { [key: string]: Definition } = {
  [Language.English]: en,
  [Language.French]: fr,
};
