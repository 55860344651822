import axios from 'axios';
import { Application } from '@my-logger/my-logger.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { MainAPIClient } from '../../services/main-api-client';
import { BusinessError, generateErrorNotification, unexpectedError } from '../../utils/errors';
import { initialState } from './state';

export const fetchUserApplications = createAsyncThunk<
  Application[],
  { userId: number },
  { extra: { mainApiClient: MainAPIClient } }
>('applications/get', async ({ userId }, thunkAPI) => {
  const { mainApiClient } = thunkAPI.extra;
  thunkAPI.dispatch(applicationsSlice.actions.startLoading());
  return await mainApiClient.getUserApplications({ userId });
});

export const manageUserApplication = createAsyncThunk<
  void,
  { userId: number; application: Pick<Application, 'name' | 'description'>; applicationId?: number },
  { extra: { mainApiClient: MainAPIClient } }
>('applications/create', async ({ userId, application, applicationId }, thunkAPI) => {
  const { mainApiClient } = thunkAPI.extra;
  thunkAPI.dispatch(applicationsSlice.actions.startLoading());

  try {
    if (!applicationId) {
      await mainApiClient.createUserApplication({ userId, application });
    } else {
      await mainApiClient.editUserApplication({ userId, application, applicationId });
    }
    await thunkAPI.dispatch(fetchUserApplications({ userId }));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.status === 409) {
        throw new BusinessError(
          `There is already an application called ${application.name}. Please find a new and unique name.`,
        );
      }
    }
    throw e;
  }
});

export const deleteApplication = createAsyncThunk<
  void,
  { userId: number; applicationId: number },
  { extra: { mainApiClient: MainAPIClient } }
>('applications/create', async ({ userId, applicationId }, thunkAPI) => {
  const { mainApiClient } = thunkAPI.extra;
  thunkAPI.dispatch(applicationsSlice.actions.startLoading());

  await mainApiClient.deleteUserApplication({ userId, applicationId });
  await thunkAPI.dispatch(fetchUserApplications({ userId }));
});

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserApplications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.applications = action.payload;
    });
    builder.addCase(fetchUserApplications.rejected, (state) => {
      state.isLoading = false;
      state.error = unexpectedError;
    });
    builder.addCase(manageUserApplication.rejected, (state, action) => {
      state.isLoading = false;
      if (action.error.name === 'BusinessError') {
        state.error = generateErrorNotification('Duplicate application name', action.error);
      } else {
        state.error = unexpectedError;
      }
    });
  },
});

export const { clearError } = applicationsSlice.actions;
export const applicationsReducer = applicationsSlice.reducer;
