import { FunctionComponent } from 'react';
import { DateRangePicker as RDateRangePicker, DateRangePickerProps as RDateRangePickerProps } from 'rsuite';
import PageNextIcon from '@rsuite/icons/PageNext';
import { createUseStyles } from 'react-jss';

import { Theme } from '../theme-provider';

export type DateRangePickerProps = RDateRangePickerProps & {
  color?: string;
};

const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    input: {
      '@global': {
        '.rs-picker-toggle-caret': {
          transform: 'rotate(90deg)',
          fontSize: 16,
        },
        '.rs-picker-toggle-textbox': {
          backgroundColor: isDark ? `${palette.grey.dark.grey02} !important` : undefined,
          color: isDark ? `${palette.grey.dark.grey05} !important` : undefined,
          border: isDark ? `1px solid ${palette.grey.dark.grey03} !important` : undefined,
          borderRadius: 6,
        },
        '.rs-picker-toggle-placeholder': {
          color: isDark ? `${palette.white} !important` : palette.black,
        },
        '.rs-picker-toggle': {
          backgroundColor: isDark ? `${palette.grey.dark.grey02} !important` : undefined,
          border: isDark ? `1px solid ${palette.grey.dark.grey03} !important` : undefined,
          borderRadius: 6,
        },
        '.rs-picker-toggle-value': {
          color: isDark ? `${palette.white} !important` : undefined,
        },
      },
    },
    menu: {
      backgroundColor: isDark ? palette.grey.dark.grey02 : undefined,
      '@global': {
        '.rs-picker-search-bar-input': {
          backgroundColor: isDark ? `${palette.grey.dark.grey02} !important` : undefined,
          border: isDark ? `1px solid ${palette.grey.dark.grey03} !important` : undefined,
        },
        '.rs-checkbox-checker': {
          color: isDark ? `${palette.grey.dark.grey05} !important` : undefined,
        },
        '.rs-picker-daterange-header': {
          borderBottom: isDark ? `1px solid ${palette.grey.dark.grey03}` : undefined,
          color: isDark ? palette.grey.dark.grey05 : undefined,
        },
        '.rs-picker-toolbar': {
          borderTop: isDark ? `1px solid ${palette.grey.dark.grey03}` : undefined,
        },
        '.rs-calendar:first-child': {
          borderRight: isDark ? `1px solid ${palette.grey.dark.grey03}` : undefined,
        },
        '.rs-btn-link': {
          color: isDark ? palette.white : undefined,
        },
        '.rs-calendar-table-cell-content': {
          color: isDark ? palette.grey.dark.grey05 : undefined,
        },
        '.rs-calendar-table-cell-un-same-month': {
          color: isDark ? palette.grey.dark.grey04 : undefined,
          opacity: 0.1,
        },
        '.rs-calendar-time-dropdown, .rs-calendar-month-dropdown-row-wrapper': {
          backgroundColor: isDark ? `${palette.grey.dark.grey02} !important` : undefined,
          border: isDark ? `1px solid ${palette.grey.dark.grey03} !important` : undefined,
        },
        '.rs-calendar-time-dropdown-column-title': {
          backgroundColor: isDark ? `${palette.grey.dark.grey03} !important` : undefined,
        },
        '.rs-calendar-btn-close': {
          backgroundColor: isDark ? `${palette.grey.dark.grey04} !important` : undefined,
        },
        '.rs-calendar-time-dropdown-cell, .rs-calendar-month-dropdown-cell-content': {
          color: isDark ? palette.grey.dark.grey05 : undefined,
        },
        '.rs-calendar-month-view .rs-calendar-header-title-date': {
          color: isDark ? palette.white : undefined,
        },
        '.rs-calendar-month-dropdown-row:not(:last-child)': {
          borderBottom: isDark ? `1px solid ${palette.grey.dark.grey03}` : undefined,
        },
        '.rs-calendar-month-dropdown': {
          borderTop: isDark ? `1px solid ${palette.grey.dark.grey03}` : undefined,
        },
        '.rs-picker-daterange-predefined': {
          borderRight: isDark ? `1px solid ${palette.grey.dark.grey03}` : undefined,
        },
      },
    },
  };
});

export const DateRangePicker: FunctionComponent<DateRangePickerProps> = ({
  style,
  className,
  color,
  format,
  character,
  ...rest
}) => {
  const classes = useStylesFromThemeFunction();

  return (
    <RDateRangePicker
      className={`${className} ${classes.input}`}
      character={character}
      color={color}
      style={style}
      format={format}
      menuClassName={classes.menu}
      caretAs={PageNextIcon}
      {...rest}
    />
  );
};
