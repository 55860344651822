import { Language } from '@my-logger/my-logger.types';
import en from './en';
import fr from './fr';

type Definition = {
  title: string;
  subTitle: string;
  darkModeLabel: string;
  languageLabel: string;
  highlightErrorsLabel: string;
  highlightWarningLabel: string;
  apiKeyLabel: string;
  apiKeyButtonText: string;
  saveButtonText: string;

  apiKeyModals: {
    passwordModal: {
      title: string;
      text: string;
    };
    confirmationModal: {
      title: string;
      text: string;
    };
  };
};

export const settings: { [key: string]: Definition } = {
  [Language.English]: en,
  [Language.French]: fr,
};
