import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';

//Ideally, would like not to have to import rsuite styles from here, but from @my-logger.ui directly
import 'rsuite/styles/index.less';
import '@my-logger/my-logger.ui/dist/esm/index.css';
import './app-theme.less';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
