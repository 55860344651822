export default {
  title: 'Settings',
  subTitle: 'Update your preferences and generate a new API key',
  darkModeLabel: 'Use dark mode',
  languageLabel: 'Language',
  highlightErrorsLabel: 'Highlight error logs',
  highlightWarningLabel: 'Highlight warning logs',
  apiKeyLabel: 'API Key',
  apiKeyButtonText: 'Generate new API key',
  saveButtonText: 'Save',
  apiKeyModals: {
    passwordModal: {
      title: 'Enter your password',
      text: 'We need you to enter your password in order to generate a new API key.',
    },
    confirmationModal: {
      title: 'Your API key',
      text: 'Make sure to save your new API key, as you won’t be able to see it again.',
    },
  },
};
