import { ClientError } from '../../types';

export type UserState = {
  isLoading: boolean;
  userId: number | null;
  error?: ClientError;
};

export const initialState: UserState = {
  isLoading: false,
  userId: null,
};
