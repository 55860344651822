import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { Button as RButton } from 'rsuite';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import { DotsLoader } from '../dots-loader';
import { palette } from '../../theme/palettes';
import { Text } from '../text';

export type ButtonStatus = 'success' | 'error';

export interface ButtonProps {
  /** Additional styling */
  style?: React.CSSProperties;
  /** Class name to apply to the button */
  className?: string;
  /** Display the three dots loader instead of the text */
  loading?: boolean;
  /** Type of button */
  type?: 'button' | 'submit' | 'reset';
  /** Function to trigger when the button is clicked */
  onClick?: () => void;
  /** Button appearance */
  appearance?: TypeAttributes.Appearance;
  /** Status to show if operation was successful or not. Can be either "success" or "error". Must be provided along with clearStatus prop */
  status?: ButtonStatus;
  /** Function to clear status. Will be triggered after 2 seconds */
  clearStatus?: () => void;
  /** Width of the button. Default and minimum are 100px */
  width?: number;
  /** Disable button */
  disabled?: boolean;
}

export const Button: FunctionComponent<PropsWithChildren & ButtonProps> = ({
  style,
  children,
  className,
  loading,
  onClick,
  status,
  clearStatus,
  width = 100,
  disabled,
  type = 'button',
  appearance = 'primary',
}) => {
  if (status && !clearStatus) {
    throw new Error('button error: status must be provided along with a clearStatus method');
  }

  if (width < 100) width = 100;

  useEffect(() => {
    if (clearStatus && status) {
      setTimeout(() => {
        clearStatus();
      }, 2000);
    }
  }, [status]);

  const content = status === 'success' ? <CheckIcon /> : status === 'error' ? <CloseIcon /> : children;

  const buttonStyle = {
    backgroundColor: status === 'error' ? 'red' : appearance === 'primary' ? palette.primary.purple1 : undefined,
    minWidth: width,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...style,
  };

  return (
    <RButton
      appearance={appearance || 'primary'}
      style={buttonStyle}
      className={className}
      type={type}
      onClick={status ? undefined : onClick}
      disabled={disabled}
    >
      {loading ? <DotsLoader /> : <Text variant="button">{content}</Text>}
    </RButton>
  );
};
