import { FunctionComponent, CSSProperties, ReactNode } from 'react';
import { JssStyle } from 'jss';
import { createUseStyles } from 'react-jss';

import { Theme } from '../theme-provider';
import { generateRandomNumberInRange } from '../utils/random-values';
import { Placeholder } from '../placeholder';
import { mobileBreakpoint } from '../../theme/breakpoints';

export type Variants = 'h1' | 'h2' | 'h3' | 'p' | 'small' | 'button' | 'label';

export interface TextProps {
  /** Text to be displayed */
  children?: string | ReactNode;
  /** Text variant. This determines the style of the text */
  variant?: Variants;
  /** Override text style */
  style?: CSSProperties;
  /** ClassName */
  className?: string;
  /** Show placeholder */
  isLoading?: boolean;
  /** Loading maximum length in px */
  loadingMaxLength?: number;
}

export const textStyles: Record<Variants, JssStyle> = {
  h1: {
    fontSize: 45,
    fontWeight: 500,
    fontFamily: 'Figtree',

    [`@media (max-width: ${mobileBreakpoint}px)`]: {
      fontSize: 32,
    },
  },
  h2: {
    fontSize: 24,
    fontFamily: 'Figtree',
    [`@media (max-width: ${mobileBreakpoint}px)`]: {
      fontSize: 18,
    },
  },
  h3: {
    fontSize: 20,
    fontFamily: 'Figtree',
    fontWeight: 600,
    [`@media (max-width: ${mobileBreakpoint}px)`]: {
      fontSize: 16,
    },
  },
  p: {
    fontSize: 16,
    fontFamily: 'Figtree',
  },
  button: {
    fontSize: 14,
    fontFamily: 'system-ui',
  },
  small: {
    fontSize: 14,
    fontFamily: 'Figtree',
  },
  label: {
    fontSize: 12,
    fontFamily: 'Figtree',
  },
};

export const useStylesFromThemeFunction = createUseStyles((theme: Theme) => {
  const { isDark, palette } = theme;

  return {
    h1: {
      ...textStyles.h1,
      color: isDark ? palette.white : palette.black,
    },
    h2: {
      ...textStyles.h2,
      color: isDark ? palette.grey.dark.grey04 : palette.grey.light.grey03,
    },
    h3: {
      ...textStyles.h3,
      color: isDark ? palette.white : palette.black,
    },
    p: {
      ...textStyles.p,
      color: isDark ? palette.grey.dark.grey05 : palette.grey.light.grey02,
    },
    button: textStyles.button,
    small: {
      ...textStyles.small,
      color: isDark ? palette.grey.dark.grey05 : palette.grey.light.grey02,
    },
    label: textStyles.label,
  };
});

export const useVariantStylesFromThemeFunction = createUseStyles({
  h1: {
    marginBottom: 6,
    height: '45px !important',
    [`@media (max-width: ${mobileBreakpoint}px)`]: {
      height: '32px !important',
    },
  },
  h2: {
    marginBottom: 6,
    height: '24px !important',
    [`@media (max-width: ${mobileBreakpoint}px)`]: {
      height: '18px !important',
    },
  },
  h3: {
    marginBottom: 6,
    height: '20px !important',
    [`@media (max-width: ${mobileBreakpoint}px)`]: {
      height: '16px !important',
    },
  },
  p: {
    marginBottom: 6,
    height: '16px !important',
  },
  button: {
    marginBottom: 6,
    height: '14px !important',
  },
  small: {
    marginBottom: 6,
    height: '14px !important',
  },
  label: {
    marginBottom: 6,
    height: '12px !important',
  },
});

export const Text: FunctionComponent<TextProps> = ({
  children,
  style,
  variant = 'p',
  className,
  loadingMaxLength,
  isLoading = false,
}) => {
  const classes = useStylesFromThemeFunction();
  const variantClasses = useVariantStylesFromThemeFunction();

  if (isLoading) {
    return (
      <Placeholder
        active
        width={generateRandomNumberInRange(80, loadingMaxLength || 300)}
        style={style}
        className={`${className} ${variantClasses[variant]}`}
      />
    );
  }
  return (
    <p style={style} className={`${className} ${classes[variant]}`}>
      {children}
    </p>
  );
};
